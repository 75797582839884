var Ps = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, s = t.length, i; n < s; n++)
      (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
};
function Vs(e) {
  return e;
}
function Q(e, t, r, n, s, i, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return s(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return i(s(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(i(s(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(i(s(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return l(c(o(i(s(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function y(e, t, r, n, s, i, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return s(n(r(t(e))));
    case 6:
      return i(s(n(r(t(e)))));
    case 7:
      return o(i(s(n(r(t(e))))));
    case 8:
      return c(o(i(s(n(r(t(e)))))));
    case 9:
      return l(c(o(i(s(n(r(t(e))))))));
    default: {
      for (var u = arguments[0], h = 1; h < arguments.length; h++)
        u = arguments[h](u);
      return u;
    }
  }
}
var Ls = function(e, t) {
  var r = typeof e == "number" ? function(n) {
    return n.length >= e;
  } : e;
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(s) {
      return t.apply(void 0, Ps([s], n, !1));
    };
  };
}, Ds = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, Us = function(e) {
  return Ds(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var Bn = function(e) {
  return e._tag === "Some";
}, Gn = { _tag: "None" }, zn = function(e) {
  return { _tag: "Some", value: e };
}, Zs = function(e) {
  return e._tag === "Left";
}, Hs = function(e) {
  return e._tag === "Right";
}, qs = function(e) {
  return { _tag: "Left", left: e };
}, Bs = function(e) {
  return { _tag: "Right", right: e };
}, Gs = function(e) {
  return [e];
}, zs = [], Fs = Object.prototype.hasOwnProperty, Ks = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, s) {
        return e.concat(n, s);
      }, t);
    };
  };
}, Ws = function(e) {
  return {
    concat: function() {
      return e;
    }
  };
}, Qs = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var s in e)
        Fs.call(e, s) && (n[s] = e[s].concat(t[s], r[s]));
      return n;
    }
  };
}, Yt = function() {
  return { concat: Vs };
}, Js = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, Ys = Ks, Xs = Ws(void 0), Wr = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, s = t.length, i; n < s; n++)
      (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, ei = zs, ti = function(e, t) {
  return e < 0 || e >= t.length;
}, ri = function(e) {
  return function(t) {
    return Wr(Wr([], t, !0), [e], !1);
  };
}, ni = ri, ai = Gs, K = {
  equals: function(e, t) {
    return e === t;
  }
}, si = {
  concat: function(e, t) {
    return e + t;
  }
}, ii = "", yr = {
  concat: si.concat,
  empty: ii
};
K.equals;
var Fn = function(e) {
  return e.trim();
}, Kn = function(e) {
  return e.length === 0;
}, ct = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Wn(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function oi() {
  this.__data__ = [], this.size = 0;
}
var ci = oi;
function ui(e, t) {
  return e === t || e !== e && t !== t;
}
var gr = ui, li = gr;
function di(e, t) {
  for (var r = e.length; r--; )
    if (li(e[r][0], t))
      return r;
  return -1;
}
var jt = di, fi = jt, pi = Array.prototype, hi = pi.splice;
function vi(e) {
  var t = this.__data__, r = fi(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : hi.call(t, r, 1), --this.size, !0;
}
var mi = vi, yi = jt;
function gi(e) {
  var t = this.__data__, r = yi(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var _i = gi, bi = jt;
function Ti(e) {
  return bi(this.__data__, e) > -1;
}
var $i = Ti, Ai = jt;
function Si(e, t) {
  var r = this.__data__, n = Ai(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Ei = Si, wi = ci, xi = mi, ki = _i, ji = $i, Ri = Ei;
function Te(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Te.prototype.clear = wi;
Te.prototype.delete = xi;
Te.prototype.get = ki;
Te.prototype.has = ji;
Te.prototype.set = Ri;
var Rt = Te, Oi = Rt;
function Ci() {
  this.__data__ = new Oi(), this.size = 0;
}
var Ii = Ci;
function Mi(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var Ni = Mi;
function Pi(e) {
  return this.__data__.get(e);
}
var Vi = Pi;
function Li(e) {
  return this.__data__.has(e);
}
var Di = Li, Ui = typeof ct == "object" && ct && ct.Object === Object && ct, Qn = Ui, Zi = Qn, Hi = typeof self == "object" && self && self.Object === Object && self, qi = Zi || Hi || Function("return this")(), G = qi, Bi = G, Gi = Bi.Symbol, Ot = Gi, Qr = Ot, Jn = Object.prototype, zi = Jn.hasOwnProperty, Fi = Jn.toString, Ne = Qr ? Qr.toStringTag : void 0;
function Ki(e) {
  var t = zi.call(e, Ne), r = e[Ne];
  try {
    e[Ne] = void 0;
    var n = !0;
  } catch {
  }
  var s = Fi.call(e);
  return n && (t ? e[Ne] = r : delete e[Ne]), s;
}
var Wi = Ki, Qi = Object.prototype, Ji = Qi.toString;
function Yi(e) {
  return Ji.call(e);
}
var Xi = Yi, Jr = Ot, eo = Wi, to = Xi, ro = "[object Null]", no = "[object Undefined]", Yr = Jr ? Jr.toStringTag : void 0;
function ao(e) {
  return e == null ? e === void 0 ? no : ro : Yr && Yr in Object(e) ? eo(e) : to(e);
}
var Ct = ao;
function so(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var We = so, io = Ct, oo = We, co = "[object AsyncFunction]", uo = "[object Function]", lo = "[object GeneratorFunction]", fo = "[object Proxy]";
function po(e) {
  if (!oo(e))
    return !1;
  var t = io(e);
  return t == uo || t == lo || t == co || t == fo;
}
var Yn = po, ho = G, vo = ho["__core-js_shared__"], mo = vo, Xt = mo, Xr = function() {
  var e = /[^.]+$/.exec(Xt && Xt.keys && Xt.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function yo(e) {
  return !!Xr && Xr in e;
}
var go = yo, _o = Function.prototype, bo = _o.toString;
function To(e) {
  if (e != null) {
    try {
      return bo.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Xn = To, $o = Yn, Ao = go, So = We, Eo = Xn, wo = /[\\^$.*+?()[\]{}|]/g, xo = /^\[object .+?Constructor\]$/, ko = Function.prototype, jo = Object.prototype, Ro = ko.toString, Oo = jo.hasOwnProperty, Co = RegExp(
  "^" + Ro.call(Oo).replace(wo, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Io(e) {
  if (!So(e) || Ao(e))
    return !1;
  var t = $o(e) ? Co : xo;
  return t.test(Eo(e));
}
var Mo = Io;
function No(e, t) {
  return e == null ? void 0 : e[t];
}
var Po = No, Vo = Mo, Lo = Po;
function Do(e, t) {
  var r = Lo(e, t);
  return Vo(r) ? r : void 0;
}
var ue = Do, Uo = ue, Zo = G, Ho = Uo(Zo, "Map"), _r = Ho, qo = ue, Bo = qo(Object, "create"), It = Bo, en = It;
function Go() {
  this.__data__ = en ? en(null) : {}, this.size = 0;
}
var zo = Go;
function Fo(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Ko = Fo, Wo = It, Qo = "__lodash_hash_undefined__", Jo = Object.prototype, Yo = Jo.hasOwnProperty;
function Xo(e) {
  var t = this.__data__;
  if (Wo) {
    var r = t[e];
    return r === Qo ? void 0 : r;
  }
  return Yo.call(t, e) ? t[e] : void 0;
}
var ec = Xo, tc = It, rc = Object.prototype, nc = rc.hasOwnProperty;
function ac(e) {
  var t = this.__data__;
  return tc ? t[e] !== void 0 : nc.call(t, e);
}
var sc = ac, ic = It, oc = "__lodash_hash_undefined__";
function cc(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = ic && t === void 0 ? oc : t, this;
}
var uc = cc, lc = zo, dc = Ko, fc = ec, pc = sc, hc = uc;
function $e(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
$e.prototype.clear = lc;
$e.prototype.delete = dc;
$e.prototype.get = fc;
$e.prototype.has = pc;
$e.prototype.set = hc;
var vc = $e, tn = vc, mc = Rt, yc = _r;
function gc() {
  this.size = 0, this.__data__ = {
    hash: new tn(),
    map: new (yc || mc)(),
    string: new tn()
  };
}
var _c = gc;
function bc(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Tc = bc, $c = Tc;
function Ac(e, t) {
  var r = e.__data__;
  return $c(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var Mt = Ac, Sc = Mt;
function Ec(e) {
  var t = Sc(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var wc = Ec, xc = Mt;
function kc(e) {
  return xc(this, e).get(e);
}
var jc = kc, Rc = Mt;
function Oc(e) {
  return Rc(this, e).has(e);
}
var Cc = Oc, Ic = Mt;
function Mc(e, t) {
  var r = Ic(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var Nc = Mc, Pc = _c, Vc = wc, Lc = jc, Dc = Cc, Uc = Nc;
function Ae(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Ae.prototype.clear = Pc;
Ae.prototype.delete = Vc;
Ae.prototype.get = Lc;
Ae.prototype.has = Dc;
Ae.prototype.set = Uc;
var ea = Ae, Zc = Rt, Hc = _r, qc = ea, Bc = 200;
function Gc(e, t) {
  var r = this.__data__;
  if (r instanceof Zc) {
    var n = r.__data__;
    if (!Hc || n.length < Bc - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new qc(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var zc = Gc, Fc = Rt, Kc = Ii, Wc = Ni, Qc = Vi, Jc = Di, Yc = zc;
function Se(e) {
  var t = this.__data__ = new Fc(e);
  this.size = t.size;
}
Se.prototype.clear = Kc;
Se.prototype.delete = Wc;
Se.prototype.get = Qc;
Se.prototype.has = Jc;
Se.prototype.set = Yc;
var ta = Se;
function Xc(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var eu = Xc, tu = ue, ru = function() {
  try {
    var e = tu(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), nu = ru, rn = nu;
function au(e, t, r) {
  t == "__proto__" && rn ? rn(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var ra = au, su = ra, iu = gr, ou = Object.prototype, cu = ou.hasOwnProperty;
function uu(e, t, r) {
  var n = e[t];
  (!(cu.call(e, t) && iu(n, r)) || r === void 0 && !(t in e)) && su(e, t, r);
}
var na = uu, lu = na, du = ra;
function fu(e, t, r, n) {
  var s = !r;
  r || (r = {});
  for (var i = -1, o = t.length; ++i < o; ) {
    var c = t[i], l = n ? n(r[c], e[c], c, r, e) : void 0;
    l === void 0 && (l = e[c]), s ? du(r, c, l) : lu(r, c, l);
  }
  return r;
}
var Nt = fu;
function pu(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var hu = pu;
function vu(e) {
  return e != null && typeof e == "object";
}
var Ee = vu, mu = Ct, yu = Ee, gu = "[object Arguments]";
function _u(e) {
  return yu(e) && mu(e) == gu;
}
var bu = _u, nn = bu, Tu = Ee, aa = Object.prototype, $u = aa.hasOwnProperty, Au = aa.propertyIsEnumerable, Su = nn(function() {
  return arguments;
}()) ? nn : function(e) {
  return Tu(e) && $u.call(e, "callee") && !Au.call(e, "callee");
}, Eu = Su, wu = Array.isArray, Pt = wu, ft = { exports: {} };
function xu() {
  return !1;
}
var ku = xu;
ft.exports;
(function(e, t) {
  var r = G, n = ku, s = t && !t.nodeType && t, i = s && !0 && e && !e.nodeType && e, o = i && i.exports === s, c = o ? r.Buffer : void 0, l = c ? c.isBuffer : void 0, u = l || n;
  e.exports = u;
})(ft, ft.exports);
var br = ft.exports, ju = 9007199254740991, Ru = /^(?:0|[1-9]\d*)$/;
function Ou(e, t) {
  var r = typeof e;
  return t = t ?? ju, !!t && (r == "number" || r != "symbol" && Ru.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Cu = Ou, Iu = 9007199254740991;
function Mu(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Iu;
}
var sa = Mu, Nu = Ct, Pu = sa, Vu = Ee, Lu = "[object Arguments]", Du = "[object Array]", Uu = "[object Boolean]", Zu = "[object Date]", Hu = "[object Error]", qu = "[object Function]", Bu = "[object Map]", Gu = "[object Number]", zu = "[object Object]", Fu = "[object RegExp]", Ku = "[object Set]", Wu = "[object String]", Qu = "[object WeakMap]", Ju = "[object ArrayBuffer]", Yu = "[object DataView]", Xu = "[object Float32Array]", el = "[object Float64Array]", tl = "[object Int8Array]", rl = "[object Int16Array]", nl = "[object Int32Array]", al = "[object Uint8Array]", sl = "[object Uint8ClampedArray]", il = "[object Uint16Array]", ol = "[object Uint32Array]", E = {};
E[Xu] = E[el] = E[tl] = E[rl] = E[nl] = E[al] = E[sl] = E[il] = E[ol] = !0;
E[Lu] = E[Du] = E[Ju] = E[Uu] = E[Yu] = E[Zu] = E[Hu] = E[qu] = E[Bu] = E[Gu] = E[zu] = E[Fu] = E[Ku] = E[Wu] = E[Qu] = !1;
function cl(e) {
  return Vu(e) && Pu(e.length) && !!E[Nu(e)];
}
var ul = cl;
function ll(e) {
  return function(t) {
    return e(t);
  };
}
var Tr = ll, pt = { exports: {} };
pt.exports;
(function(e, t) {
  var r = Qn, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i && r.process, c = function() {
    try {
      var l = s && s.require && s.require("util").types;
      return l || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(pt, pt.exports);
var $r = pt.exports, dl = ul, fl = Tr, an = $r, sn = an && an.isTypedArray, pl = sn ? fl(sn) : dl, ia = pl, hl = hu, vl = Eu, ml = Pt, yl = br, gl = Cu, _l = ia, bl = Object.prototype, Tl = bl.hasOwnProperty;
function $l(e, t) {
  var r = ml(e), n = !r && vl(e), s = !r && !n && yl(e), i = !r && !n && !s && _l(e), o = r || n || s || i, c = o ? hl(e.length, String) : [], l = c.length;
  for (var u in e)
    (t || Tl.call(e, u)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    s && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    gl(u, l))) && c.push(u);
  return c;
}
var oa = $l, Al = Object.prototype;
function Sl(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Al;
  return e === r;
}
var Ar = Sl;
function El(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var ca = El, wl = ca, xl = wl(Object.keys, Object), kl = xl, jl = Ar, Rl = kl, Ol = Object.prototype, Cl = Ol.hasOwnProperty;
function Il(e) {
  if (!jl(e))
    return Rl(e);
  var t = [];
  for (var r in Object(e))
    Cl.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var Ml = Il, Nl = Yn, Pl = sa;
function Vl(e) {
  return e != null && Pl(e.length) && !Nl(e);
}
var ua = Vl, Ll = oa, Dl = Ml, Ul = ua;
function Zl(e) {
  return Ul(e) ? Ll(e) : Dl(e);
}
var Sr = Zl, Hl = Nt, ql = Sr;
function Bl(e, t) {
  return e && Hl(t, ql(t), e);
}
var Gl = Bl;
function zl(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Fl = zl, Kl = We, Wl = Ar, Ql = Fl, Jl = Object.prototype, Yl = Jl.hasOwnProperty;
function Xl(e) {
  if (!Kl(e))
    return Ql(e);
  var t = Wl(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !Yl.call(e, n)) || r.push(n);
  return r;
}
var ed = Xl, td = oa, rd = ed, nd = ua;
function ad(e) {
  return nd(e) ? td(e, !0) : rd(e);
}
var Er = ad, sd = Nt, id = Er;
function od(e, t) {
  return e && sd(t, id(t), e);
}
var cd = od, ht = { exports: {} };
ht.exports;
(function(e, t) {
  var r = G, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function l(u, h) {
    if (h)
      return u.slice();
    var _ = u.length, A = c ? c(_) : new u.constructor(_);
    return u.copy(A), A;
  }
  e.exports = l;
})(ht, ht.exports);
var ud = ht.exports;
function ld(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var dd = ld;
function fd(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, s = 0, i = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (i[s++] = o);
  }
  return i;
}
var pd = fd;
function hd() {
  return [];
}
var la = hd, vd = pd, md = la, yd = Object.prototype, gd = yd.propertyIsEnumerable, on = Object.getOwnPropertySymbols, _d = on ? function(e) {
  return e == null ? [] : (e = Object(e), vd(on(e), function(t) {
    return gd.call(e, t);
  }));
} : md, wr = _d, bd = Nt, Td = wr;
function $d(e, t) {
  return bd(e, Td(e), t);
}
var Ad = $d;
function Sd(e, t) {
  for (var r = -1, n = t.length, s = e.length; ++r < n; )
    e[s + r] = t[r];
  return e;
}
var da = Sd, Ed = ca, wd = Ed(Object.getPrototypeOf, Object), fa = wd, xd = da, kd = fa, jd = wr, Rd = la, Od = Object.getOwnPropertySymbols, Cd = Od ? function(e) {
  for (var t = []; e; )
    xd(t, jd(e)), e = kd(e);
  return t;
} : Rd, pa = Cd, Id = Nt, Md = pa;
function Nd(e, t) {
  return Id(e, Md(e), t);
}
var Pd = Nd, Vd = da, Ld = Pt;
function Dd(e, t, r) {
  var n = t(e);
  return Ld(e) ? n : Vd(n, r(e));
}
var ha = Dd, Ud = ha, Zd = wr, Hd = Sr;
function qd(e) {
  return Ud(e, Hd, Zd);
}
var va = qd, Bd = ha, Gd = pa, zd = Er;
function Fd(e) {
  return Bd(e, zd, Gd);
}
var Kd = Fd, Wd = ue, Qd = G, Jd = Wd(Qd, "DataView"), Yd = Jd, Xd = ue, ef = G, tf = Xd(ef, "Promise"), rf = tf, nf = ue, af = G, sf = nf(af, "Set"), of = sf, cf = ue, uf = G, lf = cf(uf, "WeakMap"), df = lf, ar = Yd, sr = _r, ir = rf, or = of, cr = df, ma = Ct, we = Xn, cn = "[object Map]", ff = "[object Object]", un = "[object Promise]", ln = "[object Set]", dn = "[object WeakMap]", fn = "[object DataView]", pf = we(ar), hf = we(sr), vf = we(ir), mf = we(or), yf = we(cr), ae = ma;
(ar && ae(new ar(new ArrayBuffer(1))) != fn || sr && ae(new sr()) != cn || ir && ae(ir.resolve()) != un || or && ae(new or()) != ln || cr && ae(new cr()) != dn) && (ae = function(e) {
  var t = ma(e), r = t == ff ? e.constructor : void 0, n = r ? we(r) : "";
  if (n)
    switch (n) {
      case pf:
        return fn;
      case hf:
        return cn;
      case vf:
        return un;
      case mf:
        return ln;
      case yf:
        return dn;
    }
  return t;
});
var Vt = ae, gf = Object.prototype, _f = gf.hasOwnProperty;
function bf(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && _f.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var Tf = bf, $f = G, Af = $f.Uint8Array, ya = Af, pn = ya;
function Sf(e) {
  var t = new e.constructor(e.byteLength);
  return new pn(t).set(new pn(e)), t;
}
var xr = Sf, Ef = xr;
function wf(e, t) {
  var r = t ? Ef(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var xf = wf, kf = /\w*$/;
function jf(e) {
  var t = new e.constructor(e.source, kf.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Rf = jf, hn = Ot, vn = hn ? hn.prototype : void 0, mn = vn ? vn.valueOf : void 0;
function Of(e) {
  return mn ? Object(mn.call(e)) : {};
}
var Cf = Of, If = xr;
function Mf(e, t) {
  var r = t ? If(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var Nf = Mf, Pf = xr, Vf = xf, Lf = Rf, Df = Cf, Uf = Nf, Zf = "[object Boolean]", Hf = "[object Date]", qf = "[object Map]", Bf = "[object Number]", Gf = "[object RegExp]", zf = "[object Set]", Ff = "[object String]", Kf = "[object Symbol]", Wf = "[object ArrayBuffer]", Qf = "[object DataView]", Jf = "[object Float32Array]", Yf = "[object Float64Array]", Xf = "[object Int8Array]", ep = "[object Int16Array]", tp = "[object Int32Array]", rp = "[object Uint8Array]", np = "[object Uint8ClampedArray]", ap = "[object Uint16Array]", sp = "[object Uint32Array]";
function ip(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case Wf:
      return Pf(e);
    case Zf:
    case Hf:
      return new n(+e);
    case Qf:
      return Vf(e, r);
    case Jf:
    case Yf:
    case Xf:
    case ep:
    case tp:
    case rp:
    case np:
    case ap:
    case sp:
      return Uf(e, r);
    case qf:
      return new n();
    case Bf:
    case Ff:
      return new n(e);
    case Gf:
      return Lf(e);
    case zf:
      return new n();
    case Kf:
      return Df(e);
  }
}
var op = ip, cp = We, yn = Object.create, up = function() {
  function e() {
  }
  return function(t) {
    if (!cp(t))
      return {};
    if (yn)
      return yn(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), lp = up, dp = lp, fp = fa, pp = Ar;
function hp(e) {
  return typeof e.constructor == "function" && !pp(e) ? dp(fp(e)) : {};
}
var vp = hp, mp = Vt, yp = Ee, gp = "[object Map]";
function _p(e) {
  return yp(e) && mp(e) == gp;
}
var bp = _p, Tp = bp, $p = Tr, gn = $r, _n = gn && gn.isMap, Ap = _n ? $p(_n) : Tp, Sp = Ap, Ep = Vt, wp = Ee, xp = "[object Set]";
function kp(e) {
  return wp(e) && Ep(e) == xp;
}
var jp = kp, Rp = jp, Op = Tr, bn = $r, Tn = bn && bn.isSet, Cp = Tn ? Op(Tn) : Rp, Ip = Cp, Mp = ta, Np = eu, Pp = na, Vp = Gl, Lp = cd, Dp = ud, Up = dd, Zp = Ad, Hp = Pd, qp = va, Bp = Kd, Gp = Vt, zp = Tf, Fp = op, Kp = vp, Wp = Pt, Qp = br, Jp = Sp, Yp = We, Xp = Ip, eh = Sr, th = Er, rh = 1, nh = 2, ah = 4, ga = "[object Arguments]", sh = "[object Array]", ih = "[object Boolean]", oh = "[object Date]", ch = "[object Error]", _a = "[object Function]", uh = "[object GeneratorFunction]", lh = "[object Map]", dh = "[object Number]", ba = "[object Object]", fh = "[object RegExp]", ph = "[object Set]", hh = "[object String]", vh = "[object Symbol]", mh = "[object WeakMap]", yh = "[object ArrayBuffer]", gh = "[object DataView]", _h = "[object Float32Array]", bh = "[object Float64Array]", Th = "[object Int8Array]", $h = "[object Int16Array]", Ah = "[object Int32Array]", Sh = "[object Uint8Array]", Eh = "[object Uint8ClampedArray]", wh = "[object Uint16Array]", xh = "[object Uint32Array]", S = {};
S[ga] = S[sh] = S[yh] = S[gh] = S[ih] = S[oh] = S[_h] = S[bh] = S[Th] = S[$h] = S[Ah] = S[lh] = S[dh] = S[ba] = S[fh] = S[ph] = S[hh] = S[vh] = S[Sh] = S[Eh] = S[wh] = S[xh] = !0;
S[ch] = S[_a] = S[mh] = !1;
function lt(e, t, r, n, s, i) {
  var o, c = t & rh, l = t & nh, u = t & ah;
  if (r && (o = s ? r(e, n, s, i) : r(e)), o !== void 0)
    return o;
  if (!Yp(e))
    return e;
  var h = Wp(e);
  if (h) {
    if (o = zp(e), !c)
      return Up(e, o);
  } else {
    var _ = Gp(e), A = _ == _a || _ == uh;
    if (Qp(e))
      return Dp(e, c);
    if (_ == ba || _ == ga || A && !s) {
      if (o = l || A ? {} : Kp(e), !c)
        return l ? Hp(e, Lp(o, e)) : Zp(e, Vp(o, e));
    } else {
      if (!S[_])
        return s ? e : {};
      o = Fp(e, _, c);
    }
  }
  i || (i = new Mp());
  var C = i.get(e);
  if (C)
    return C;
  i.set(e, o), Xp(e) ? e.forEach(function(O) {
    o.add(lt(O, t, r, O, e, i));
  }) : Jp(e) && e.forEach(function(O, M) {
    o.set(M, lt(O, t, r, M, e, i));
  });
  var P = u ? l ? Bp : qp : l ? th : eh, V = h ? void 0 : P(e);
  return Np(V || e, function(O, M) {
    V && (M = O, O = e[M]), Pp(o, M, lt(O, t, r, M, e, i));
  }), o;
}
var kh = lt, jh = kh, Rh = 1, Oh = 4;
function Ch(e) {
  return jh(e, Rh | Oh);
}
var Ih = Ch;
const Mh = /* @__PURE__ */ Wn(Ih);
var $;
(function(e) {
  e.assertEqual = (s) => s;
  function t(s) {
  }
  e.assertIs = t;
  function r(s) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (s) => {
    const i = {};
    for (const o of s)
      i[o] = o;
    return i;
  }, e.getValidEnumValues = (s) => {
    const i = e.objectKeys(s).filter((c) => typeof s[s[c]] != "number"), o = {};
    for (const c of i)
      o[c] = s[c];
    return e.objectValues(o);
  }, e.objectValues = (s) => e.objectKeys(s).map(function(i) {
    return s[i];
  }), e.objectKeys = typeof Object.keys == "function" ? (s) => Object.keys(s) : (s) => {
    const i = [];
    for (const o in s)
      Object.prototype.hasOwnProperty.call(s, o) && i.push(o);
    return i;
  }, e.find = (s, i) => {
    for (const o of s)
      if (i(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (s) => Number.isInteger(s) : (s) => typeof s == "number" && isFinite(s) && Math.floor(s) === s;
  function n(s, i = " | ") {
    return s.map((o) => typeof o == "string" ? `'${o}'` : o).join(i);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (s, i) => typeof i == "bigint" ? i.toString() : i;
})($ || ($ = {}));
var ur;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(ur || (ur = {}));
const f = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), W = (e) => {
  switch (typeof e) {
    case "undefined":
      return f.undefined;
    case "string":
      return f.string;
    case "number":
      return isNaN(e) ? f.nan : f.number;
    case "boolean":
      return f.boolean;
    case "function":
      return f.function;
    case "bigint":
      return f.bigint;
    case "symbol":
      return f.symbol;
    case "object":
      return Array.isArray(e) ? f.array : e === null ? f.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? f.promise : typeof Map < "u" && e instanceof Map ? f.map : typeof Set < "u" && e instanceof Set ? f.set : typeof Date < "u" && e instanceof Date ? f.date : f.object;
    default:
      return f.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), Nh = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class D extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(i) {
      return i.message;
    }, n = { _errors: [] }, s = (i) => {
      for (const o of i.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(s);
        else if (o.code === "invalid_return_type")
          s(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          s(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, l = 0;
          for (; l < o.path.length; ) {
            const u = o.path[l];
            l === o.path.length - 1 ? (c[u] = c[u] || { _errors: [] }, c[u]._errors.push(r(o))) : c[u] = c[u] || { _errors: [] }, c = c[u], l++;
          }
        }
    };
    return s(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const s of this.issues)
      s.path.length > 0 ? (r[s.path[0]] = r[s.path[0]] || [], r[s.path[0]].push(t(s))) : n.push(t(s));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
D.create = (e) => new D(e);
const Pe = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === f.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let Ta = Pe;
function Ph(e) {
  Ta = e;
}
function vt() {
  return Ta;
}
const mt = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: s } = e, i = [...r, ...s.path || []], o = {
    ...s,
    path: i
  };
  let c = "";
  const l = n.filter((u) => !!u).slice().reverse();
  for (const u of l)
    c = u(o, { data: t, defaultError: c }).message;
  return {
    ...s,
    path: i,
    message: s.message || c
  };
}, Vh = [];
function p(e, t) {
  const r = mt({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      vt(),
      Pe
      // then global default map
    ].filter((n) => !!n)
  });
  e.common.issues.push(r);
}
class I {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const s of r) {
      if (s.status === "aborted")
        return g;
      s.status === "dirty" && t.dirty(), n.push(s.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const s of r)
      n.push({
        key: await s.key,
        value: await s.value
      });
    return I.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const s of r) {
      const { key: i, value: o } = s;
      if (i.status === "aborted" || o.status === "aborted")
        return g;
      i.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), i.value !== "__proto__" && (typeof o.value < "u" || s.alwaysSet) && (n[i.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const g = Object.freeze({
  status: "aborted"
}), $a = (e) => ({ status: "dirty", value: e }), N = (e) => ({ status: "valid", value: e }), lr = (e) => e.status === "aborted", dr = (e) => e.status === "dirty", Ve = (e) => e.status === "valid", yt = (e) => typeof Promise < "u" && e instanceof Promise;
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
class H {
  constructor(t, r, n, s) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = s;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const $n = (e, t) => {
  if (Ve(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new D(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function b(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: s } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: s } : { errorMap: (o, c) => o.code !== "invalid_type" ? { message: c.defaultError } : typeof c.data > "u" ? { message: n ?? c.defaultError } : { message: r ?? c.defaultError }, description: s };
}
class T {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return W(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: W(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new I(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: W(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (yt(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const s = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: W(t)
    }, i = this._parseSync({ data: t, path: s.path, parent: s });
    return $n(s, i);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: W(t)
    }, s = this._parse({ data: t, path: n.path, parent: n }), i = await (yt(s) ? s : Promise.resolve(s));
    return $n(n, i);
  }
  refine(t, r) {
    const n = (s) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(s) : r;
    return this._refinement((s, i) => {
      const o = t(s), c = () => i.addIssue({
        code: d.custom,
        ...n(s)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((l) => l ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, s) => t(n) ? !0 : (s.addIssue(typeof r == "function" ? r(n, s) : r), !1));
  }
  _refinement(t) {
    return new Z({
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return z.create(this, this._def);
  }
  nullable() {
    return ce.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return U.create(this, this._def);
  }
  promise() {
    return me.create(this, this._def);
  }
  or(t) {
    return Ze.create([this, t], this._def);
  }
  and(t) {
    return He.create(this, t, this._def);
  }
  transform(t) {
    return new Z({
      ...b(this._def),
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Fe({
      ...b(this._def),
      innerType: this,
      defaultValue: r,
      typeName: m.ZodDefault
    });
  }
  brand() {
    return new Sa({
      typeName: m.ZodBranded,
      type: this,
      ...b(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Tt({
      ...b(this._def),
      innerType: this,
      catchValue: r,
      typeName: m.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return Qe.create(this, t);
  }
  readonly() {
    return At.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const Lh = /^c[^\s-]{8,}$/i, Dh = /^[a-z][a-z0-9]*$/, Uh = /^[0-9A-HJKMNP-TV-Z]{26}$/, Zh = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, Hh = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, qh = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let er;
const Bh = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, Gh = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, zh = (e) => e.precision ? e.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}Z$`) : e.precision === 0 ? e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function Fh(e, t) {
  return !!((t === "v4" || !t) && Bh.test(e) || (t === "v6" || !t) && Gh.test(e));
}
class L extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== f.string) {
      const i = this._getOrReturnCtx(t);
      return p(
        i,
        {
          code: d.invalid_type,
          expected: f.string,
          received: i.parsedType
        }
        //
      ), g;
    }
    const n = new I();
    let s;
    for (const i of this._def.checks)
      if (i.kind === "min")
        t.data.length < i.value && (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "max")
        t.data.length > i.value && (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "length") {
        const o = t.data.length > i.value, c = t.data.length < i.value;
        (o || c) && (s = this._getOrReturnCtx(t, s), o ? p(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }) : c && p(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }), n.dirty());
      } else if (i.kind === "email")
        Hh.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "email",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "emoji")
        er || (er = new RegExp(qh, "u")), er.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "emoji",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "uuid")
        Zh.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "uuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid")
        Lh.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "cuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid2")
        Dh.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "cuid2",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "ulid")
        Uh.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "ulid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "url")
        try {
          new URL(t.data);
        } catch {
          s = this._getOrReturnCtx(t, s), p(s, {
            validation: "url",
            code: d.invalid_string,
            message: i.message
          }), n.dirty();
        }
      else
        i.kind === "regex" ? (i.regex.lastIndex = 0, i.regex.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "regex",
          code: d.invalid_string,
          message: i.message
        }), n.dirty())) : i.kind === "trim" ? t.data = t.data.trim() : i.kind === "includes" ? t.data.includes(i.value, i.position) || (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.invalid_string,
          validation: { includes: i.value, position: i.position },
          message: i.message
        }), n.dirty()) : i.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : i.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : i.kind === "startsWith" ? t.data.startsWith(i.value) || (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.invalid_string,
          validation: { startsWith: i.value },
          message: i.message
        }), n.dirty()) : i.kind === "endsWith" ? t.data.endsWith(i.value) || (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.invalid_string,
          validation: { endsWith: i.value },
          message: i.message
        }), n.dirty()) : i.kind === "datetime" ? zh(i).test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.invalid_string,
          validation: "datetime",
          message: i.message
        }), n.dirty()) : i.kind === "ip" ? Fh(t.data, i.version) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "ip",
          code: d.invalid_string,
          message: i.message
        }), n.dirty()) : $.assertNever(i);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((s) => t.test(s), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new L({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
L.create = (e) => {
  var t;
  return new L({
    checks: [],
    typeName: m.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
function Kh(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, s = r > n ? r : n, i = parseInt(e.toFixed(s).replace(".", "")), o = parseInt(t.toFixed(s).replace(".", ""));
  return i % o / Math.pow(10, s);
}
class J extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== f.number) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: f.number,
        received: i.parsedType
      }), g;
    }
    let n;
    const s = new I();
    for (const i of this._def.checks)
      i.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: i.message
      }), s.dirty()) : i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? Kh(t.data, i.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : i.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new J({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new J({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
J.create = (e) => new J({
  checks: [],
  typeName: m.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class Y extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== f.bigint) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: f.bigint,
        received: i.parsedType
      }), g;
    }
    let n;
    const s = new I();
    for (const i of this._def.checks)
      i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? t.data % i.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new Y({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Y({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
Y.create = (e) => {
  var t;
  return new Y({
    checks: [],
    typeName: m.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
class Le extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== f.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.boolean,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
Le.create = (e) => new Le({
  typeName: m.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class ie extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== f.date) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: f.date,
        received: i.parsedType
      }), g;
    }
    if (isNaN(t.data.getTime())) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_date
      }), g;
    }
    const n = new I();
    let s;
    for (const i of this._def.checks)
      i.kind === "min" ? t.data.getTime() < i.value && (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.too_small,
        message: i.message,
        inclusive: !0,
        exact: !1,
        minimum: i.value,
        type: "date"
      }), n.dirty()) : i.kind === "max" ? t.data.getTime() > i.value && (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.too_big,
        message: i.message,
        inclusive: !0,
        exact: !1,
        maximum: i.value,
        type: "date"
      }), n.dirty()) : $.assertNever(i);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ie({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ie.create = (e) => new ie({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: m.ZodDate,
  ...b(e)
});
class gt extends T {
  _parse(t) {
    if (this._getType(t) !== f.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.symbol,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
gt.create = (e) => new gt({
  typeName: m.ZodSymbol,
  ...b(e)
});
class De extends T {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.undefined,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
De.create = (e) => new De({
  typeName: m.ZodUndefined,
  ...b(e)
});
class Ue extends T {
  _parse(t) {
    if (this._getType(t) !== f.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.null,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
Ue.create = (e) => new Ue({
  typeName: m.ZodNull,
  ...b(e)
});
class ve extends T {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
ve.create = (e) => new ve({
  typeName: m.ZodAny,
  ...b(e)
});
class se extends T {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
se.create = (e) => new se({
  typeName: m.ZodUnknown,
  ...b(e)
});
class F extends T {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: f.never,
      received: r.parsedType
    }), g;
  }
}
F.create = (e) => new F({
  typeName: m.ZodNever,
  ...b(e)
});
class _t extends T {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.void,
        received: n.parsedType
      }), g;
    }
    return N(t.data);
  }
}
_t.create = (e) => new _t({
  typeName: m.ZodVoid,
  ...b(e)
});
class U extends T {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), s = this._def;
    if (r.parsedType !== f.array)
      return p(r, {
        code: d.invalid_type,
        expected: f.array,
        received: r.parsedType
      }), g;
    if (s.exactLength !== null) {
      const o = r.data.length > s.exactLength.value, c = r.data.length < s.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? s.exactLength.value : void 0,
        maximum: o ? s.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: s.exactLength.message
      }), n.dirty());
    }
    if (s.minLength !== null && r.data.length < s.minLength.value && (p(r, {
      code: d.too_small,
      minimum: s.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.minLength.message
    }), n.dirty()), s.maxLength !== null && r.data.length > s.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: s.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => s.type._parseAsync(new H(r, o, r.path, c)))).then((o) => I.mergeArray(n, o));
    const i = [...r.data].map((o, c) => s.type._parseSync(new H(r, o, r.path, c)));
    return I.mergeArray(n, i);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new U({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new U({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new U({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
U.create = (e, t) => new U({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: m.ZodArray,
  ...b(t)
});
function pe(e) {
  if (e instanceof x) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = z.create(pe(n));
    }
    return new x({
      ...e._def,
      shape: () => t
    });
  } else
    return e instanceof U ? new U({
      ...e._def,
      type: pe(e.element)
    }) : e instanceof z ? z.create(pe(e.unwrap())) : e instanceof ce ? ce.create(pe(e.unwrap())) : e instanceof q ? q.create(e.items.map((t) => pe(t))) : e;
}
class x extends T {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== f.object) {
      const u = this._getOrReturnCtx(t);
      return p(u, {
        code: d.invalid_type,
        expected: f.object,
        received: u.parsedType
      }), g;
    }
    const { status: n, ctx: s } = this._processInputParams(t), { shape: i, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof F && this._def.unknownKeys === "strip"))
      for (const u in s.data)
        o.includes(u) || c.push(u);
    const l = [];
    for (const u of o) {
      const h = i[u], _ = s.data[u];
      l.push({
        key: { status: "valid", value: u },
        value: h._parse(new H(s, _, s.path, u)),
        alwaysSet: u in s.data
      });
    }
    if (this._def.catchall instanceof F) {
      const u = this._def.unknownKeys;
      if (u === "passthrough")
        for (const h of c)
          l.push({
            key: { status: "valid", value: h },
            value: { status: "valid", value: s.data[h] }
          });
      else if (u === "strict")
        c.length > 0 && (p(s, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (u !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const u = this._def.catchall;
      for (const h of c) {
        const _ = s.data[h];
        l.push({
          key: { status: "valid", value: h },
          value: u._parse(
            new H(s, _, s.path, h)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: h in s.data
        });
      }
    }
    return s.common.async ? Promise.resolve().then(async () => {
      const u = [];
      for (const h of l) {
        const _ = await h.key;
        u.push({
          key: _,
          value: await h.value,
          alwaysSet: h.alwaysSet
        });
      }
      return u;
    }).then((u) => I.mergeObjectSync(n, u)) : I.mergeObjectSync(n, l);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new x({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var s, i, o, c;
          const l = (o = (i = (s = this._def).errorMap) === null || i === void 0 ? void 0 : i.call(s, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : l
          } : {
            message: l
          };
        }
      } : {}
    });
  }
  strip() {
    return new x({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new x({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new x({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new x({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: m.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new x({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return pe(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const s = this.shape[n];
      t && !t[n] ? r[n] = s : r[n] = s.optional();
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let i = this.shape[n];
        for (; i instanceof z; )
          i = i._def.innerType;
        r[n] = i;
      }
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return Aa($.objectKeys(this.shape));
  }
}
x.create = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strip",
  catchall: F.create(),
  typeName: m.ZodObject,
  ...b(t)
});
x.strictCreate = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strict",
  catchall: F.create(),
  typeName: m.ZodObject,
  ...b(t)
});
x.lazycreate = (e, t) => new x({
  shape: e,
  unknownKeys: "strip",
  catchall: F.create(),
  typeName: m.ZodObject,
  ...b(t)
});
class Ze extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function s(i) {
      for (const c of i)
        if (c.result.status === "valid")
          return c.result;
      for (const c of i)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = i.map((c) => new D(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), g;
    }
    if (r.common.async)
      return Promise.all(n.map(async (i) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await i._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(s);
    {
      let i;
      const o = [];
      for (const l of n) {
        const u = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, h = l._parseSync({
          data: r.data,
          path: r.path,
          parent: u
        });
        if (h.status === "valid")
          return h;
        h.status === "dirty" && !i && (i = { result: h, ctx: u }), u.common.issues.length && o.push(u.common.issues);
      }
      if (i)
        return r.common.issues.push(...i.ctx.common.issues), i.result;
      const c = o.map((l) => new D(l));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), g;
    }
  }
  get options() {
    return this._def.options;
  }
}
Ze.create = (e, t) => new Ze({
  options: e,
  typeName: m.ZodUnion,
  ...b(t)
});
const dt = (e) => e instanceof Be ? dt(e.schema) : e instanceof Z ? dt(e.innerType()) : e instanceof Ge ? [e.value] : e instanceof X ? e.options : e instanceof ze ? Object.keys(e.enum) : e instanceof Fe ? dt(e._def.innerType) : e instanceof De ? [void 0] : e instanceof Ue ? [null] : null;
class Lt extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.object)
      return p(r, {
        code: d.invalid_type,
        expected: f.object,
        received: r.parsedType
      }), g;
    const n = this.discriminator, s = r.data[n], i = this.optionsMap.get(s);
    return i ? r.common.async ? i._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : i._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), g);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const s = /* @__PURE__ */ new Map();
    for (const i of r) {
      const o = dt(i.shape[t]);
      if (!o)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (s.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        s.set(c, i);
      }
    }
    return new Lt({
      typeName: m.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: s,
      ...b(n)
    });
  }
}
function fr(e, t) {
  const r = W(e), n = W(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === f.object && n === f.object) {
    const s = $.objectKeys(t), i = $.objectKeys(e).filter((c) => s.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of i) {
      const l = fr(e[c], t[c]);
      if (!l.valid)
        return { valid: !1 };
      o[c] = l.data;
    }
    return { valid: !0, data: o };
  } else if (r === f.array && n === f.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const s = [];
    for (let i = 0; i < e.length; i++) {
      const o = e[i], c = t[i], l = fr(o, c);
      if (!l.valid)
        return { valid: !1 };
      s.push(l.data);
    }
    return { valid: !0, data: s };
  } else
    return r === f.date && n === f.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class He extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = (i, o) => {
      if (lr(i) || lr(o))
        return g;
      const c = fr(i.value, o.value);
      return c.valid ? ((dr(i) || dr(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), g);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([i, o]) => s(i, o)) : s(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
He.create = (e, t, r) => new He({
  left: e,
  right: t,
  typeName: m.ZodIntersection,
  ...b(r)
});
class q extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.array)
      return p(n, {
        code: d.invalid_type,
        expected: f.array,
        received: n.parsedType
      }), g;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), g;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const i = [...n.data].map((o, c) => {
      const l = this._def.items[c] || this._def.rest;
      return l ? l._parse(new H(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(i).then((o) => I.mergeArray(r, o)) : I.mergeArray(r, i);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new q({
      ...this._def,
      rest: t
    });
  }
}
q.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new q({
    items: e,
    typeName: m.ZodTuple,
    rest: null,
    ...b(t)
  });
};
class qe extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.object)
      return p(n, {
        code: d.invalid_type,
        expected: f.object,
        received: n.parsedType
      }), g;
    const s = [], i = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      s.push({
        key: i._parse(new H(n, c, n.path, c)),
        value: o._parse(new H(n, n.data[c], n.path, c))
      });
    return n.common.async ? I.mergeObjectAsync(r, s) : I.mergeObjectSync(r, s);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof T ? new qe({
      keyType: t,
      valueType: r,
      typeName: m.ZodRecord,
      ...b(n)
    }) : new qe({
      keyType: L.create(),
      valueType: t,
      typeName: m.ZodRecord,
      ...b(r)
    });
  }
}
class bt extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.map)
      return p(n, {
        code: d.invalid_type,
        expected: f.map,
        received: n.parsedType
      }), g;
    const s = this._def.keyType, i = this._def.valueType, o = [...n.data.entries()].map(([c, l], u) => ({
      key: s._parse(new H(n, c, n.path, [u, "key"])),
      value: i._parse(new H(n, l, n.path, [u, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const l of o) {
          const u = await l.key, h = await l.value;
          if (u.status === "aborted" || h.status === "aborted")
            return g;
          (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const l of o) {
        const u = l.key, h = l.value;
        if (u.status === "aborted" || h.status === "aborted")
          return g;
        (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
      }
      return { status: r.value, value: c };
    }
  }
}
bt.create = (e, t, r) => new bt({
  valueType: t,
  keyType: e,
  typeName: m.ZodMap,
  ...b(r)
});
class oe extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.set)
      return p(n, {
        code: d.invalid_type,
        expected: f.set,
        received: n.parsedType
      }), g;
    const s = this._def;
    s.minSize !== null && n.data.size < s.minSize.value && (p(n, {
      code: d.too_small,
      minimum: s.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.minSize.message
    }), r.dirty()), s.maxSize !== null && n.data.size > s.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: s.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.maxSize.message
    }), r.dirty());
    const i = this._def.valueType;
    function o(l) {
      const u = /* @__PURE__ */ new Set();
      for (const h of l) {
        if (h.status === "aborted")
          return g;
        h.status === "dirty" && r.dirty(), u.add(h.value);
      }
      return { status: r.value, value: u };
    }
    const c = [...n.data.values()].map((l, u) => i._parse(new H(n, l, n.path, u)));
    return n.common.async ? Promise.all(c).then((l) => o(l)) : o(c);
  }
  min(t, r) {
    return new oe({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new oe({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
oe.create = (e, t) => new oe({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: m.ZodSet,
  ...b(t)
});
class he extends T {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.function)
      return p(r, {
        code: d.invalid_type,
        expected: f.function,
        received: r.parsedType
      }), g;
    function n(c, l) {
      return mt({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          vt(),
          Pe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: l
        }
      });
    }
    function s(c, l) {
      return mt({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          vt(),
          Pe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: l
        }
      });
    }
    const i = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof me) {
      const c = this;
      return N(async function(...l) {
        const u = new D([]), h = await c._def.args.parseAsync(l, i).catch((C) => {
          throw u.addIssue(n(l, C)), u;
        }), _ = await Reflect.apply(o, this, h);
        return await c._def.returns._def.type.parseAsync(_, i).catch((C) => {
          throw u.addIssue(s(_, C)), u;
        });
      });
    } else {
      const c = this;
      return N(function(...l) {
        const u = c._def.args.safeParse(l, i);
        if (!u.success)
          throw new D([n(l, u.error)]);
        const h = Reflect.apply(o, this, u.data), _ = c._def.returns.safeParse(h, i);
        if (!_.success)
          throw new D([s(h, _.error)]);
        return _.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new he({
      ...this._def,
      args: q.create(t).rest(se.create())
    });
  }
  returns(t) {
    return new he({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new he({
      args: t || q.create([]).rest(se.create()),
      returns: r || se.create(),
      typeName: m.ZodFunction,
      ...b(n)
    });
  }
}
class Be extends T {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Be.create = (e, t) => new Be({
  getter: e,
  typeName: m.ZodLazy,
  ...b(t)
});
class Ge extends T {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), g;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Ge.create = (e, t) => new Ge({
  value: e,
  typeName: m.ZodLiteral,
  ...b(t)
});
function Aa(e, t) {
  return new X({
    values: e,
    typeName: m.ZodEnum,
    ...b(t)
  });
}
class X extends T {
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (this._def.values.indexOf(t.data) === -1) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), g;
    }
    return N(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t) {
    return X.create(t);
  }
  exclude(t) {
    return X.create(this.options.filter((r) => !t.includes(r)));
  }
}
X.create = Aa;
class ze extends T {
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== f.string && n.parsedType !== f.number) {
      const s = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(s),
        received: n.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (r.indexOf(t.data) === -1) {
      const s = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: s
      }), g;
    }
    return N(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
ze.create = (e, t) => new ze({
  values: e,
  typeName: m.ZodNativeEnum,
  ...b(t)
});
class me extends T {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: f.promise,
        received: r.parsedType
      }), g;
    const n = r.parsedType === f.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((s) => this._def.type.parseAsync(s, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
me.create = (e, t) => new me({
  type: e,
  typeName: m.ZodPromise,
  ...b(t)
});
class Z extends T {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === m.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = this._def.effect || null, i = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (i.addIssue = i.addIssue.bind(i), s.type === "preprocess") {
      const o = s.transform(n.data, i);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(o).then((c) => this._def.schema._parseAsync({
        data: c,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: o,
        path: n.path,
        parent: n
      });
    }
    if (s.type === "refinement") {
      const o = (c) => {
        const l = s.refinement(c, i);
        if (n.common.async)
          return Promise.resolve(l);
        if (l instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (s.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!Ve(o))
          return o;
        const c = s.transform(o.value, i);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => Ve(o) ? Promise.resolve(s.transform(o.value, i)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(s);
  }
}
Z.create = (e, t, r) => new Z({
  schema: e,
  typeName: m.ZodEffects,
  effect: t,
  ...b(r)
});
Z.createWithPreprocess = (e, t, r) => new Z({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: m.ZodEffects,
  ...b(r)
});
class z extends T {
  _parse(t) {
    return this._getType(t) === f.undefined ? N(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
z.create = (e, t) => new z({
  innerType: e,
  typeName: m.ZodOptional,
  ...b(t)
});
class ce extends T {
  _parse(t) {
    return this._getType(t) === f.null ? N(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ce.create = (e, t) => new ce({
  innerType: e,
  typeName: m.ZodNullable,
  ...b(t)
});
class Fe extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === f.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Fe.create = (e, t) => new Fe({
  innerType: e,
  typeName: m.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ...b(t)
});
class Tt extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, s = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return yt(s) ? s.then((i) => ({
      status: "valid",
      value: i.status === "valid" ? i.value : this._def.catchValue({
        get error() {
          return new D(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new D(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
Tt.create = (e, t) => new Tt({
  innerType: e,
  typeName: m.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ...b(t)
});
class $t extends T {
  _parse(t) {
    if (this._getType(t) !== f.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.nan,
        received: n.parsedType
      }), g;
    }
    return { status: "valid", value: t.data };
  }
}
$t.create = (e) => new $t({
  typeName: m.ZodNaN,
  ...b(e)
});
const Wh = Symbol("zod_brand");
class Sa extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class Qe extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const i = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return i.status === "aborted" ? g : i.status === "dirty" ? (r.dirty(), $a(i.value)) : this._def.out._parseAsync({
          data: i.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const s = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return s.status === "aborted" ? g : s.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: s.value
      }) : this._def.out._parseSync({
        data: s.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new Qe({
      in: t,
      out: r,
      typeName: m.ZodPipeline
    });
  }
}
class At extends T {
  _parse(t) {
    const r = this._def.innerType._parse(t);
    return Ve(r) && (r.value = Object.freeze(r.value)), r;
  }
}
At.create = (e, t) => new At({
  innerType: e,
  typeName: m.ZodReadonly,
  ...b(t)
});
const Ea = (e, t = {}, r) => e ? ve.create().superRefine((n, s) => {
  var i, o;
  if (!e(n)) {
    const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, l = (o = (i = c.fatal) !== null && i !== void 0 ? i : r) !== null && o !== void 0 ? o : !0, u = typeof c == "string" ? { message: c } : c;
    s.addIssue({ code: "custom", ...u, fatal: l });
  }
}) : ve.create(), Qh = {
  object: x.lazycreate
};
var m;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(m || (m = {}));
const Jh = (e, t = {
  message: `Input not instance of ${e.name}`
}) => Ea((r) => r instanceof e, t), wa = L.create, xa = J.create, Yh = $t.create, Xh = Y.create, ka = Le.create, ev = ie.create, tv = gt.create, rv = De.create, nv = Ue.create, av = ve.create, sv = se.create, iv = F.create, ov = _t.create, cv = U.create, uv = x.create, lv = x.strictCreate, dv = Ze.create, fv = Lt.create, pv = He.create, hv = q.create, vv = qe.create, mv = bt.create, yv = oe.create, gv = he.create, _v = Be.create, bv = Ge.create, Tv = X.create, $v = ze.create, Av = me.create, An = Z.create, Sv = z.create, Ev = ce.create, wv = Z.createWithPreprocess, xv = Qe.create, kv = () => wa().optional(), jv = () => xa().optional(), Rv = () => ka().optional(), Ov = {
  string: (e) => L.create({ ...e, coerce: !0 }),
  number: (e) => J.create({ ...e, coerce: !0 }),
  boolean: (e) => Le.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => Y.create({ ...e, coerce: !0 }),
  date: (e) => ie.create({ ...e, coerce: !0 })
}, Cv = g;
var a = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: Pe,
  setErrorMap: Ph,
  getErrorMap: vt,
  makeIssue: mt,
  EMPTY_PATH: Vh,
  addIssueToContext: p,
  ParseStatus: I,
  INVALID: g,
  DIRTY: $a,
  OK: N,
  isAborted: lr,
  isDirty: dr,
  isValid: Ve,
  isAsync: yt,
  get util() {
    return $;
  },
  get objectUtil() {
    return ur;
  },
  ZodParsedType: f,
  getParsedType: W,
  ZodType: T,
  ZodString: L,
  ZodNumber: J,
  ZodBigInt: Y,
  ZodBoolean: Le,
  ZodDate: ie,
  ZodSymbol: gt,
  ZodUndefined: De,
  ZodNull: Ue,
  ZodAny: ve,
  ZodUnknown: se,
  ZodNever: F,
  ZodVoid: _t,
  ZodArray: U,
  ZodObject: x,
  ZodUnion: Ze,
  ZodDiscriminatedUnion: Lt,
  ZodIntersection: He,
  ZodTuple: q,
  ZodRecord: qe,
  ZodMap: bt,
  ZodSet: oe,
  ZodFunction: he,
  ZodLazy: Be,
  ZodLiteral: Ge,
  ZodEnum: X,
  ZodNativeEnum: ze,
  ZodPromise: me,
  ZodEffects: Z,
  ZodTransformer: Z,
  ZodOptional: z,
  ZodNullable: ce,
  ZodDefault: Fe,
  ZodCatch: Tt,
  ZodNaN: $t,
  BRAND: Wh,
  ZodBranded: Sa,
  ZodPipeline: Qe,
  ZodReadonly: At,
  custom: Ea,
  Schema: T,
  ZodSchema: T,
  late: Qh,
  get ZodFirstPartyTypeKind() {
    return m;
  },
  coerce: Ov,
  any: av,
  array: cv,
  bigint: Xh,
  boolean: ka,
  date: ev,
  discriminatedUnion: fv,
  effect: An,
  enum: Tv,
  function: gv,
  instanceof: Jh,
  intersection: pv,
  lazy: _v,
  literal: bv,
  map: mv,
  nan: Yh,
  nativeEnum: $v,
  never: iv,
  null: nv,
  nullable: Ev,
  number: xa,
  object: uv,
  oboolean: Rv,
  onumber: jv,
  optional: Sv,
  ostring: kv,
  pipeline: xv,
  preprocess: wv,
  promise: Av,
  record: vv,
  set: yv,
  strictObject: lv,
  string: wa,
  symbol: tv,
  transformer: An,
  tuple: hv,
  undefined: rv,
  union: dv,
  unknown: sv,
  void: ov,
  NEVER: Cv,
  ZodIssueCode: d,
  quotelessJson: Nh,
  ZodError: D
}), w = (e) => e, Iv = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let s = e;
    for (let i = t + 1; i <= this.latestVersion; i++) {
      const o = this.versionMap[i];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: i }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: i }
        };
      s = o.up(s);
    }
    return { type: "ok", value: s };
  }
};
function Je(e) {
  return new Iv(e.versionMap, e.latestVersion, e.getVersion);
}
function St(e) {
  return a.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
var Mv = "__lodash_hash_undefined__";
function Nv(e) {
  return this.__data__.set(e, Mv), this;
}
var Pv = Nv;
function Vv(e) {
  return this.__data__.has(e);
}
var Lv = Vv, Dv = ea, Uv = Pv, Zv = Lv;
function Et(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new Dv(); ++t < r; )
    this.add(e[t]);
}
Et.prototype.add = Et.prototype.push = Uv;
Et.prototype.has = Zv;
var Hv = Et;
function qv(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Bv = qv;
function Gv(e, t) {
  return e.has(t);
}
var zv = Gv, Fv = Hv, Kv = Bv, Wv = zv, Qv = 1, Jv = 2;
function Yv(e, t, r, n, s, i) {
  var o = r & Qv, c = e.length, l = t.length;
  if (c != l && !(o && l > c))
    return !1;
  var u = i.get(e), h = i.get(t);
  if (u && h)
    return u == t && h == e;
  var _ = -1, A = !0, C = r & Jv ? new Fv() : void 0;
  for (i.set(e, t), i.set(t, e); ++_ < c; ) {
    var P = e[_], V = t[_];
    if (n)
      var O = o ? n(V, P, _, t, e, i) : n(P, V, _, e, t, i);
    if (O !== void 0) {
      if (O)
        continue;
      A = !1;
      break;
    }
    if (C) {
      if (!Kv(t, function(M, ne) {
        if (!Wv(C, ne) && (P === M || s(P, M, r, n, i)))
          return C.push(ne);
      })) {
        A = !1;
        break;
      }
    } else if (!(P === V || s(P, V, r, n, i))) {
      A = !1;
      break;
    }
  }
  return i.delete(e), i.delete(t), A;
}
var ja = Yv;
function Xv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, s) {
    r[++t] = [s, n];
  }), r;
}
var em = Xv;
function tm(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var rm = tm, Sn = Ot, En = ya, nm = gr, am = ja, sm = em, im = rm, om = 1, cm = 2, um = "[object Boolean]", lm = "[object Date]", dm = "[object Error]", fm = "[object Map]", pm = "[object Number]", hm = "[object RegExp]", vm = "[object Set]", mm = "[object String]", ym = "[object Symbol]", gm = "[object ArrayBuffer]", _m = "[object DataView]", wn = Sn ? Sn.prototype : void 0, tr = wn ? wn.valueOf : void 0;
function bm(e, t, r, n, s, i, o) {
  switch (r) {
    case _m:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case gm:
      return !(e.byteLength != t.byteLength || !i(new En(e), new En(t)));
    case um:
    case lm:
    case pm:
      return nm(+e, +t);
    case dm:
      return e.name == t.name && e.message == t.message;
    case hm:
    case mm:
      return e == t + "";
    case fm:
      var c = sm;
    case vm:
      var l = n & om;
      if (c || (c = im), e.size != t.size && !l)
        return !1;
      var u = o.get(e);
      if (u)
        return u == t;
      n |= cm, o.set(e, t);
      var h = am(c(e), c(t), n, s, i, o);
      return o.delete(e), h;
    case ym:
      if (tr)
        return tr.call(e) == tr.call(t);
  }
  return !1;
}
var Tm = bm, xn = va, $m = 1, Am = Object.prototype, Sm = Am.hasOwnProperty;
function Em(e, t, r, n, s, i) {
  var o = r & $m, c = xn(e), l = c.length, u = xn(t), h = u.length;
  if (l != h && !o)
    return !1;
  for (var _ = l; _--; ) {
    var A = c[_];
    if (!(o ? A in t : Sm.call(t, A)))
      return !1;
  }
  var C = i.get(e), P = i.get(t);
  if (C && P)
    return C == t && P == e;
  var V = !0;
  i.set(e, t), i.set(t, e);
  for (var O = o; ++_ < l; ) {
    A = c[_];
    var M = e[A], ne = t[A];
    if (n)
      var Kr = o ? n(ne, M, A, t, e, i) : n(M, ne, A, e, t, i);
    if (!(Kr === void 0 ? M === ne || s(M, ne, r, n, i) : Kr)) {
      V = !1;
      break;
    }
    O || (O = A == "constructor");
  }
  if (V && !O) {
    var it = e.constructor, ot = t.constructor;
    it != ot && "constructor" in e && "constructor" in t && !(typeof it == "function" && it instanceof it && typeof ot == "function" && ot instanceof ot) && (V = !1);
  }
  return i.delete(e), i.delete(t), V;
}
var wm = Em, rr = ta, xm = ja, km = Tm, jm = wm, kn = Vt, jn = Pt, Rn = br, Rm = ia, Om = 1, On = "[object Arguments]", Cn = "[object Array]", ut = "[object Object]", Cm = Object.prototype, In = Cm.hasOwnProperty;
function Im(e, t, r, n, s, i) {
  var o = jn(e), c = jn(t), l = o ? Cn : kn(e), u = c ? Cn : kn(t);
  l = l == On ? ut : l, u = u == On ? ut : u;
  var h = l == ut, _ = u == ut, A = l == u;
  if (A && Rn(e)) {
    if (!Rn(t))
      return !1;
    o = !0, h = !1;
  }
  if (A && !h)
    return i || (i = new rr()), o || Rm(e) ? xm(e, t, r, n, s, i) : km(e, t, l, r, n, s, i);
  if (!(r & Om)) {
    var C = h && In.call(e, "__wrapped__"), P = _ && In.call(t, "__wrapped__");
    if (C || P) {
      var V = C ? e.value() : e, O = P ? t.value() : t;
      return i || (i = new rr()), s(V, O, r, n, i);
    }
  }
  return A ? (i || (i = new rr()), jm(e, t, r, n, s, i)) : !1;
}
var Mm = Im, Nm = Mm, Mn = Ee;
function Ra(e, t, r, n, s) {
  return e === t ? !0 : e == null || t == null || !Mn(e) && !Mn(t) ? e !== e && t !== t : Nm(e, t, r, n, Ra, s);
}
var Pm = Ra, Vm = Pm;
function Lm(e, t) {
  return Vm(e, t);
}
var Dm = Lm;
const Um = /* @__PURE__ */ Wn(Dm), Zm = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), nr = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), fe = {
  equals(e, t) {
    return Um(e, t);
  }
}, Hm = a.object({
  id: a.optional(a.string()),
  // Firebase Firestore ID
  url: a.string(),
  path: a.string(),
  headers: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  params: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  name: a.string(),
  method: a.string(),
  preRequestScript: a.string(),
  testScript: a.string(),
  contentType: a.string(),
  body: a.string(),
  rawParams: a.optional(a.string()),
  auth: a.optional(a.string()),
  httpUser: a.optional(a.string()),
  httpPassword: a.optional(a.string()),
  bearerToken: a.optional(a.string())
}), Nn = w({
  initial: !0,
  schema: Hm
}), kr = a.object({
  key: a.string(),
  active: a.boolean()
}).and(
  a.union([
    a.object({
      isFile: a.literal(!0),
      value: a.array(a.instanceof(Blob).nullable())
    }),
    a.object({
      isFile: a.literal(!1),
      value: a.string()
    })
  ])
), l_ = a.object({
  contentType: a.literal("multipart/form-data"),
  body: a.array(kr)
}), qm = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(kr).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), xe = a.object({
  authType: a.literal("none")
}), ke = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), je = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), Bm = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), Ye = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), Re = a.object({
  authType: a.literal("inherit")
}), Gm = a.discriminatedUnion("authType", [
  xe,
  Re,
  ke,
  je,
  Bm,
  Ye
]).and(
  a.object({
    authActive: a.boolean()
  })
), zm = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Oa = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Ca = a.object({
  v: a.literal("1"),
  id: a.optional(a.string()),
  // Firebase Firestore ID
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  params: zm,
  headers: Oa,
  preRequestScript: a.string().catch(""),
  testScript: a.string().catch(""),
  auth: Gm,
  body: qm
});
function Fm(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function Km(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const Wm = w({
  initial: !1,
  schema: Ca,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: l
    } = e, u = `${t}${r}`, h = Fm(e), _ = Km(e), A = {
      v: "1",
      endpoint: u,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: l,
      body: h,
      auth: _
    };
    return e.id && (A.id = e.id), A;
  }
}), jr = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Ia = Ca.extend({
  v: a.literal("2"),
  requestVariables: jr
}), Qm = w({
  initial: !1,
  schema: Ia,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), Ma = a.object({
  grantType: a.literal("AUTHORIZATION_CODE"),
  authEndpoint: a.string().trim(),
  tokenEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch(""),
  isPKCE: a.boolean(),
  codeVerifierMethod: a.union([a.literal("plain"), a.literal("S256")]).optional()
}), Dt = a.object({
  grantType: a.literal("CLIENT_CREDENTIALS"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Ut = a.object({
  grantType: a.literal("PASSWORD"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  username: a.string().trim(),
  password: a.string().trim(),
  token: a.string().catch("")
}), Zt = a.object({
  grantType: a.literal("IMPLICIT"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Ht = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ma,
    Dt,
    Ut,
    Zt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Jm = a.discriminatedUnion("authType", [
  xe,
  Re,
  ke,
  je,
  Ht,
  Ye
]).and(
  a.object({
    authActive: a.boolean()
  })
), Na = Ia.extend({
  v: a.literal("3"),
  auth: Jm
}), Ym = w({
  initial: !1,
  schema: Na,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
}), Pa = Ye.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Xm = a.discriminatedUnion("authType", [
  xe,
  Re,
  ke,
  je,
  Ht,
  Pa
]).and(
  a.object({
    authActive: a.boolean()
  })
), Va = Na.extend({
  v: a.literal("4"),
  auth: Xm
}), ey = w({
  schema: Va,
  initial: !1,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: "4",
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      auth: {
        ...e.auth
      },
      v: "4"
    };
  }
}), La = Ma.extend({
  clientSecret: a.string().optional()
}), qt = Ht.extend({
  grantTypeInfo: a.discriminatedUnion("grantType", [
    La,
    Dt,
    Ut,
    Zt
  ])
}), Da = a.discriminatedUnion("authType", [
  xe,
  Re,
  ke,
  je,
  qt,
  Pa
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ua = Va.extend({
  v: a.literal("5"),
  auth: Da
}), ty = w({
  schema: Ua,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "5"
    };
  }
}), Rr = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(kr).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Za = Ua.extend({
  v: a.literal("6"),
  body: Rr
}), ry = w({
  schema: Za,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "6"
    };
  }
}), Ha = La.extend({
  refreshToken: a.string().optional()
}), qa = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ha,
    Dt,
    Ut,
    Zt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Or = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), Bt = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), Cr = a.object({
  authType: a.literal("aws-signature"),
  accessKey: a.string().catch(""),
  secretKey: a.string().catch(""),
  region: a.string().catch(""),
  serviceName: a.string().catch(""),
  serviceToken: a.string().optional(),
  signature: a.object({}).optional(),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Ba = a.discriminatedUnion("authType", [
  xe,
  Re,
  ke,
  je,
  qa,
  Ye,
  Cr
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ga = Za.extend({
  v: a.literal("7"),
  params: Or,
  headers: Bt,
  auth: Ba
}), ny = w({
  schema: Ga,
  initial: !1,
  up(e) {
    const t = e.params.map((n) => ({
      ...n,
      description: ""
    })), r = e.headers.map((n) => ({
      ...n,
      description: ""
    }));
    return {
      ...e,
      v: "7",
      params: t,
      headers: r
      // no need to update anything for HoppRESTAuth, because the newly added refreshToken is optional
    };
  }
}), ay = {
  // 1xx Informational
  // Request received, continuing process.[2]
  // This class of status code indicates a provisional response, consisting only of the Status-Line and optional headers, and is terminated by an empty line. Since HTTP/1.0 did not define any 1xx status codes, servers must not send a 1xx response to an HTTP/1.0 client except under experimental conditions.
  100: "Continue",
  // This means that the server has received the request headers, and that the client should proceed to send the request body (in the case of a request for which a body needs to be sent; for example, a POST request). If the request body is large, sending it to a server when a request has already been rejected based upon inappropriate headers is inefficient. To have a server check if the request could be accepted based on the request's headers alone, a client must send Expect: 100-continue as a header in its initial request[2] and check if a 100 Continue status code is received in response before continuing (or receive 417 Expectation Failed and not continue).[2]
  101: "Switching Protocols",
  // This means the requester has asked the server to switch protocols and the server is acknowledging that it will do so.[2]
  102: "Processing",
  // (WebDAV; RFC 2518) As a WebDAV request may contain many sub-requests involving file operations, it may take a long time to complete the request. This code indicates that the server has received and is processing the request, but no response is available yet.[3] This prevents the client from timing out and assuming the request was lost.
  // 2xx Success
  // This class of status codes indicates the action requested by the client was received, understood, accepted and processed successfully.
  200: "OK",
  // Standard response for successful HTTP requests. The actual response will depend on the request method used. In a GET request, the response will contain an entity corresponding to the requested resource. In a POST request the response will contain an entity describing or containing the result of the action.[2]
  201: "Created",
  // The request has been fulfilled and resulted in a new resource being created.[2]
  202: "Accepted",
  // The request has been accepted for processing, but the processing has not been completed. The request might or might not eventually be acted upon, as it might be disallowed when processing actually takes place.[2]
  203: "Non-Authoritative Information",
  // (since HTTP/1.1) The server successfully processed the request, but is returning information that may be from another source.[2]
  204: "No Content",
  // The server successfully processed the request, but is not returning any content.[2]
  205: "Reset Content",
  // The server successfully processed the request, but is not returning any content. Unlike a 204 response, this response requires that the requester reset the document view.[2]
  206: "Partial Content",
  // The server is delivering only part of the resource due to a range header sent by the client. The range header is used by tools like wget to enable resuming of interrupted downloads, or split a download into multiple simultaneous streams.[2]
  207: "Multi-Status",
  // (WebDAV; RFC 4918) The message body that follows is an XML message and can contain a number of separate response codes, depending on how many sub-requests were made.[4]
  208: "Already Reported",
  // (WebDAV; RFC 5842) The members of a DAV binding have already been enumerated in a previous reply to this request, and are not being included again.
  226: "IM Used",
  // (RFC 3229) The server has fulfilled a GET request for the resource, and the response is a representation of the result of one or more instance-manipulations applied to the current instance. [5]
  // 3xx Redirection
  // The client must take additional action to complete the request.[2]
  // This class of status code indicates that further action needs to be taken by the user agent in order to fulfil the request. The action required may be carried out by the user agent without interaction with the user if and only if the method used in the second request is GET or HEAD. A user agent should not automatically redirect a request more than five times, since such redirections usually indicate an infinite loop.
  300: "Multiple Choices",
  // Indicates multiple options for the resource that the client may follow. It, for instance, could be used to present different format options for video, list files with different extensions, or word sense disambiguation.[2]
  301: "Moved Permanently",
  // This and all future requests should be directed to the given URI.[2]
  302: "Found",
  // This is an example of industry practice contradicting the standard.[2] The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect (the original describing phrase was "Moved Temporarily"),[6] but popular browsers implemented 302 with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307 to distinguish between the two behaviours.[7] However, some Web applications and frameworks use the 302 status code as if it were the 303.[citation needed]
  303: "See Other",
  // (since HTTP/1.1) The response to the request can be found under another URI using a GET method. When received in response to a POST (or PUT/DELETE), it should be assumed that the server has received the data and the redirect should be issued with a separate GET message.[2]
  304: "Not Modified",
  // Indicates the resource has not been modified since last requested.[2] Typically, the HTTP client provides a header like the If-Modified-Since header to provide a time against which to compare. Using this saves bandwidth and reprocessing on both the server and client, as only the header data must be sent and received in comparison to the entirety of the page being re-processed by the server, then sent again using more bandwidth of the server and client.
  305: "Use Proxy",
  // (since HTTP/1.1) Many HTTP clients (such as Mozilla[8] and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.[2]
  306: "Switch Proxy",
  // No longer used.[2] Originally meant "Subsequent requests should use the specified proxy."[9]
  307: "Temporary Redirect",
  // (since HTTP/1.1) In this case, the request should be repeated with another URI; however, future requests can still use the original URI.[2] In contrast to 302, the request method should not be changed when reissuing the original request. For instance, a POST request must be repeated using another POST request.
  308: "Permanent Redirect",
  // (experimental Internet-Draft)[10] The request, and all future requests should be repeated using another URI. 307 and 308 (as proposed) parallel the behaviours of 302 and 301, but do not require the HTTP method to change. So, for example, submitting a form to a permanently redirected resource may continue smoothly.
  // 4xx Client Error
  // The 4xx class of status code is intended for cases in which the client seems to have erred. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and whether it is a temporary or permanent condition. These status codes are applicable to any request method. User agents should display any included entity to the user.
  400: "Bad Request",
  // The request cannot be fulfilled due to bad syntax.[2]
  401: "Unauthorized",
  // Similar to 403 Forbidden, but specifically for use when authentication is possible but has failed or not yet been provided.[2] The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource. See Basic access authentication and Digest access authentication.
  402: "Payment Required",
  // Reserved for future use.[2] The original intention was that this code might be used as part of some form of digital cash or micropayment scheme, but that has not happened, and this code is not usually used. As an example of its use, however, Apple's MobileMe service generates a 402 error ("httpStatusCode:402" in the Mac OS X Console log) if the MobileMe account is delinquent.[citation needed]
  403: "Forbidden",
  // The request was a legal request, but the server is refusing to respond to it.[2] Unlike a 401 Unauthorized response, authenticating will make no difference.[2]
  404: "Not Found",
  // The requested resource could not be found but may be available again in the future.[2] Subsequent requests by the client are permissible.
  405: "Method Not Allowed",
  // A request was made of a resource using a request method not supported by that resource;[2] for example, using GET on a form which requires data to be presented via POST, or using PUT on a read-only resource.
  406: "Not Acceptable",
  // The requested resource is only capable of generating content not acceptable according to the Accept headers sent in the request.[2]
  407: "Proxy Authentication Required",
  // The client must first authenticate itself with the proxy.[2]
  408: "Request Timeout",
  // The server timed out waiting for the request.[2] According to W3 HTTP specifications: "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
  409: "Conflict",
  // Indicates that the request could not be processed because of conflict in the request, such as an edit conflict.[2]
  410: "Gone",
  // Indicates that the resource requested is no longer available and will not be available again.[2] This should be used when a resource has been intentionally removed and the resource should be purged. Upon receiving a 410 status code, the client should not request the resource again in the future. Clients such as search engines should remove the resource from their indices. Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
  411: "Length Required",
  // The request did not specify the length of its content, which is required by the requested resource.[2]
  412: "Precondition Failed",
  // The server does not meet one of the preconditions that the requester put on the request.[2]
  413: "Request Entity Too Large",
  // The request is larger than the server is willing or able to process.[2]
  414: "Request-URI Too Long",
  // The URI provided was too long for the server to process.[2]
  415: "Unsupported Media Type",
  // The request entity has a media type which the server or resource does not support.[2] For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
  416: "Requested Range Not Satisfiable",
  // The client has asked for a portion of the file, but the server cannot supply that portion.[2] For example, if the client asked for a part of the file that lies beyond the end of the file.[2]
  417: "Expectation Failed",
  // The server cannot meet the requirements of the Expect request-header field.[2]
  418: "I'm a teapot",
  // (RFC 2324) This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers. However, known implementations do exist.[11]
  420: "Enhance Your Calm",
  // (Twitter) Returned by the Twitter Search and Trends API when the client is being rate limited.[12] Likely a reference to this number's association with marijuana. Other services may wish to implement the 429 Too Many Requests response code instead. The phrase "Enhance Your Calm" is a reference to Demolition Man (film). In the film, Sylvester Stallone's character John Spartan is a hot-head in a generally more subdued future, and is regularly told to "Enhance your calm" rather than a more common phrase like "calm down".
  422: "Unprocessable Entity",
  // (WebDAV; RFC 4918) The request was well-formed but was unable to be followed due to semantic errors.[4]
  423: "Locked",
  // (WebDAV; RFC 4918) The resource that is being accessed is locked.[4]
  424: "Failed Dependency",
  // (WebDAV; RFC 4918) The request failed due to failure of a previous request (e.g. a PROPPATCH).[4]
  425: "Unordered Collection",
  // (Internet draft) Defined in drafts of "WebDAV Advanced Collections Protocol",[14] but not present in "Web Distributed Authoring and Versioning (WebDAV) Ordered Collections Protocol".[15]
  426: "Upgrade Required",
  // (RFC 2817) The client should switch to a different protocol such as TLS/1.0.[16]
  428: "Precondition Required",
  // (RFC 6585) The origin server requires the request to be conditional. Intended to prevent "the 'lost update' problem, where a client GETs a resource's state, modifies it, and PUTs it back to the server, when meanwhile a third party has modified the state on the server, leading to a conflict."[17]
  429: "Too Many Requests",
  // (RFC 6585) The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes.[17]
  431: "Request Header Fields Too Large",
  // (RFC 6585) The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.[17]
  444: "No Response",
  // (Nginx) Used in Nginx logs to indicate that the server has returned no information to the client and closed the connection (useful as a deterrent for malware).
  449: "Retry With",
  // (Microsoft) A Microsoft extension. The request should be retried after performing the appropriate action.[18] Often search-engines or custom applications will ignore required parameters. Where no default action is appropriate, the Aviongoo website sends a "HTTP/1.1 449 Retry with valid parameters: param1, param2, . . ." response. The applications may choose to learn, or not.
  450: "Blocked by Windows Parental Controls",
  // (Microsoft) A Microsoft extension. This error is given when Windows Parental Controls are turned on and are blocking access to the given webpage.[19]
  451: "Unavailable For Legal Reasons",
  // (Internet draft) Defined in the internet draft "A New HTTP Status Code for Legally-restricted Resources",[20]. Intended to be used when resource access is denied for legal reasons, e.g. censorship or government-mandated blocked access. Likely a reference to the 1953 dystopian novel Fahrenheit 451, where books are outlawed.
  499: "Client Closed Request",
  // (Nginx) Used in Nginx logs to indicate when the connection has been closed by client while the server is still processing its request, making server unable to send a status code back.[21]
  // 5xx Server Error
  // The server failed to fulfill an apparently valid request.[2]
  // Response status codes beginning with the digit "5" indicate cases in which the server is aware that it has encountered an error or is otherwise incapable of performing the request. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and indicate whether it is a temporary or permanent condition. Likewise, user agents should display any included entity to the user. These response codes are applicable to any request method.
  500: "Internal Server Error",
  // A generic error message, given when no more specific message is suitable.[2]
  501: "Not Implemented",
  // The server either does not recognise the request method, or it lacks the ability to fulfill the request.[2]
  502: "Bad Gateway",
  // The server was acting as a gateway or proxy and received an invalid response from the upstream server.[2]
  503: "Service Unavailable",
  // The server is currently unavailable (because it is overloaded or down for maintenance).[2] Generally, this is a temporary state.
  504: "Gateway Timeout",
  // The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.[2]
  505: "HTTP Version Not Supported",
  // The server does not support the HTTP protocol version used in the request.[2]
  506: "Variant Also Negotiates",
  // (RFC 2295) Transparent content negotiation for the request results in a circular reference.[22]
  507: "Insufficient Storage",
  // (WebDAV; RFC 4918) The server is unable to store the representation needed to complete the request.[4]
  508: "Loop Detected",
  // (WebDAV; RFC 5842) The server detected an infinite loop while processing the request (sent in lieu of 208).
  509: "Bandwidth Limit Exceeded",
  // (Apache bw/limited extension) This status code, while used by many servers, is not specified in any RFCs.
  510: "Not Extended",
  // (RFC 2774) Further extensions to the request are required for the server to fulfill it.[23]
  511: "Network Authentication Required",
  // (RFC 6585) The client needs to authenticate to gain network access. Intended for use by intercepting proxies used to control access to the network (e.g. "captive portals" used to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).[17]
  598: "Network read timeout error",
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network read timeout behind the proxy to a client in front of the proxy.
  599: "Network connect timeout error"
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network connect timeout behind the proxy to a client in front of the proxy.
}, sy = Dt.extend({
  clientSecret: a.string().optional()
}), iy = Ut.extend({
  clientSecret: a.string().optional()
}), oy = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ha,
    sy,
    iy,
    Zt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Gt = a.discriminatedUnion("authType", [
  xe,
  Re,
  ke,
  je,
  oy,
  Ye,
  Cr
]).and(
  a.object({
    authActive: a.boolean()
  })
), cy = a.union(
  Object.keys(ay).map((e) => a.literal(parseInt(e)))
), uy = a.array(
  a.object({
    key: a.string(),
    value: a.string()
  })
), ly = a.object({
  v: a.literal("1"),
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  headers: Bt,
  params: Or,
  body: Rr,
  auth: Gt,
  requestVariables: jr
}), dy = a.object({
  name: a.string(),
  originalRequest: ly,
  status: a.string(),
  code: a.optional(cy),
  headers: uy,
  body: a.string()
}), za = a.record(
  a.string(),
  dy
), fy = Ga.extend({
  v: a.literal("8"),
  auth: Gt,
  responses: za
}), py = w({
  schema: fy,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "8",
      // no need to update anything for HoppRESTAuth, because we loosened the previous schema by making `clientSecret` optional
      responses: {}
    };
  }
}), hy = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "text/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, d_ = Object.keys(
  hy
), vy = a.object({
  // v is a stringified number
  v: a.string().regex(/^\d+$/).transform(Number)
}), zt = Je({
  latestVersion: 8,
  versionMap: {
    0: Nn,
    1: Wm,
    2: Qm,
    3: Ym,
    4: ey,
    5: ty,
    6: ry,
    7: ny,
    8: py
  },
  getVersion(e) {
    const t = vy.safeParse(e);
    return t.success ? t.data.v : Nn.schema.safeParse(e).success ? 0 : null;
  }
}), my = Us({
  id: Zm(K),
  v: K,
  auth: fe,
  body: fe,
  endpoint: K,
  headers: nr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    fe
  ),
  params: nr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    fe
  ),
  method: K,
  name: K,
  preRequestScript: K,
  testScript: K,
  requestVariables: nr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    fe
  ),
  responses: fe
}), Fa = "8", f_ = my.equals;
function p_(e, t) {
  const r = Mh(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = Rr.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = Gt.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = Or.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = Bt.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = jr.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
    if ("responses" in e) {
      const n = za.safeParse(e.responses);
      n.success && (r.responses = n.data);
    }
  }
  return r;
}
function h_(e) {
  return {
    v: Fa,
    ...e
  };
}
function yy() {
  return {
    v: Fa,
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: [],
    responses: {}
  };
}
function v_(e) {
  return zt.isLatest(e);
}
function gy(e) {
  const t = zt.safeParse(e);
  return t.type === "ok" ? t.value : yy();
}
const Ir = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0)
}), _y = a.object({
  v: a.literal(1),
  name: a.string(),
  url: a.string(),
  headers: a.array(Ir).catch([]),
  query: a.string(),
  variables: a.string()
}), by = w({
  initial: !0,
  schema: _y
}), Oe = a.object({
  authType: a.literal("none")
}), Ce = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), Ie = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), Ty = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), Mr = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), Me = a.object({
  authType: a.literal("inherit")
}), $y = a.discriminatedUnion("authType", [
  Oe,
  Ce,
  Ie,
  Ty,
  Mr,
  Me
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ka = a.object({
  id: a.optional(a.string()),
  v: a.literal(2),
  name: a.string(),
  url: a.string(),
  headers: a.array(Ir).catch([]),
  query: a.string(),
  variables: a.string(),
  auth: $y
}), Ay = w({
  initial: !1,
  schema: Ka,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), Sy = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Mr,
  Ht
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), Wa = Ka.extend({
  v: a.literal(3),
  auth: Sy
}), Ey = w({
  initial: !1,
  schema: Wa,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), Ft = Mr.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), wy = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Ft,
  qt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), Qa = Wa.extend({
  v: a.literal(4),
  auth: wy
}), xy = w({
  initial: !1,
  schema: Qa,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: 4,
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      v: 4,
      auth: {
        ...e.auth
      }
    };
  }
}), Ja = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Ft,
  qt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ya = Qa.extend({
  v: a.literal(5),
  auth: Ja
}), ky = w({
  initial: !1,
  schema: Ya,
  up(e) {
    return {
      ...e,
      v: 5
    };
  }
}), Xa = Cr, es = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0),
  description: a.string().catch("")
}), ts = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  qt,
  Ft,
  Xa
]).and(
  a.object({
    authActive: a.boolean()
  })
), rs = Ya.extend({
  v: a.literal(6),
  auth: ts,
  headers: a.array(es).catch([])
}), jy = w({
  schema: rs,
  initial: !1,
  up(e) {
    const t = e.headers.map((r) => ({
      ...r,
      description: ""
    }));
    return {
      ...e,
      v: 6,
      headers: t
    };
  }
}), ns = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  qa,
  Ft,
  Xa
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ry = rs.extend({
  v: a.literal(7),
  auth: ns
}), Oy = w({
  schema: Ry,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: 7
      // no need to update anything for HoppGQLAuth, because we loosened the previous schema by making `clientSecret` optional
    };
  }
}), as = 7, Cy = a.object({
  v: a.number()
}), Nr = Je({
  latestVersion: 7,
  versionMap: {
    1: by,
    2: Ay,
    3: Ey,
    4: xy,
    5: ky,
    6: jy,
    7: Oy
  },
  getVersion(e) {
    const t = Cy.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), Iy = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function My() {
  return {
    v: as,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: Iy,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function Ny(e) {
  const t = Nr.safeParse(e);
  return t.type === "ok" ? t.value : My();
}
function m_(e) {
  return {
    v: as,
    ...e
  };
}
const Py = a.object({
  v: a.literal(1),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        St(zt),
        St(Nr)
      ])
    )
  )
}), ss = Py.extend({
  folders: a.lazy(() => a.array(ss))
}), Pn = w({
  initial: !0,
  schema: ss
}), is = a.object({
  v: a.literal(2),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        St(zt),
        St(Nr)
      ])
    )
  ),
  auth: a.union([Da, Ja]),
  headers: a.union([Oa, a.array(Ir)])
}), os = is.extend({
  folders: a.lazy(() => a.array(os))
}), Vy = w({
  initial: !1,
  schema: os,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), cs = is.extend({
  v: a.literal(3),
  // AWS Signature Authorization type addition
  auth: a.union([Ba, ts]),
  // `description` field addition under `headers`
  headers: a.union([Bt, a.array(es)])
}), us = cs.extend({
  folders: a.lazy(() => a.array(us))
}), Ly = w({
  initial: !1,
  schema: us,
  up(e) {
    const t = e.headers.map(
      (n) => ({
        ...n,
        description: ""
      })
    );
    return {
      ...e,
      v: 3,
      headers: t
    };
  }
}), Dy = cs.extend({
  v: a.literal(4),
  auth: a.union([Gt, ns])
}), ls = Dy.extend({
  folders: a.lazy(() => a.array(ls))
}), Uy = w({
  initial: !1,
  schema: ls,
  // @ts-expect-error
  up(e) {
    return {
      ...e,
      v: 4
    };
  }
}), Zy = a.object({
  v: a.number()
}), y_ = Je({
  latestVersion: 4,
  versionMap: {
    1: Pn,
    2: Vy,
    3: Ly,
    4: Uy
  },
  getVersion(e) {
    const t = Zy.safeParse(e);
    return t.success ? t.data.v : Pn.schema.safeParse(e).success ? 1 : null;
  }
}), Hy = 4;
function ds(e) {
  return {
    v: Hy,
    ...e
  };
}
function qy(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(qy), n = (e.requests ?? []).map(gy), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = ds({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), o;
}
function By(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(By), n = (e.requests ?? []).map(Ny), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = ds({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), o;
}
var te = function(e) {
  return function(t) {
    return !e(t);
  };
};
globalThis && globalThis.__spreadArray;
var Gy = ni, zy = ti;
function fs(e, t) {
  return t === void 0 ? function(r) {
    return fs(e, r);
  } : zy(e, t) ? Gn : zn(t[e]);
}
var ps = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Fy = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var s = e(n, t[n]);
      Bn(s) && r.push(s.value);
    }
    return r;
  };
}, Ky = function(e) {
  return Fy(function(t, r) {
    return e(r);
  });
}, hs = function(e) {
  return e.slice();
}, Wy = ei, Vn = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, s = t.length, i; n < s; n++)
      (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, Qy = function(e) {
  return function(t) {
    return Vn(Vn([], t, !0), [e], !1);
  };
}, Jy = Qy, Yy = function(e) {
  return [e];
}, Xy = Jy, eg = fs, tg = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, rg = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, ng = function() {
  return {
    concat: rg().concat,
    empty: []
  };
}, Kt = Gn, Xe = zn;
function vs(e) {
  return function(t) {
    return e(t) ? Xe(t) : Kt;
  };
}
var pr = function(e) {
  return function(t) {
    return ms(t) ? Kt : Xe(e(t.value));
  };
}, ag = Bn, ms = function(e) {
  return e._tag === "None";
}, sg = function(e, t) {
  return function(r) {
    return ms(r) ? e() : t(r.value);
  };
}, ig = sg, Pr = ig, og = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, re = qs, B = Bs, cg = /* @__PURE__ */ Ls(2, function(e, t) {
  return ye(e) ? e : t(e.right);
}), et = function(e) {
  return function(t) {
    return ye(t) ? t : B(e(t.right));
  };
}, Wt = function(e) {
  return function(t) {
    return ye(t) ? re(e(t.left)) : t;
  };
}, ye = Zs, ug = Hs, lg = function(e) {
  return function(t) {
    return ye(t) ? e(t.left) : t.right;
  };
}, Vr = lg, Ln = cg, wt = globalThis && globalThis.__assign || function() {
  return wt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t)
        Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, wt.apply(this, arguments);
}, tt = function(e, t, r) {
  return B({
    value: e,
    next: t,
    start: r
  });
}, rt = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), re({
    input: e,
    expected: t,
    fatal: r
  });
}, dg = function(e, t) {
  return wt(wt({}, e), { expected: t });
}, fg = function(e, t) {
  return pg().concat(e, t);
}, pg = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? Js().concat(e, t) : e.input.cursor > t.input.cursor ? Yt().concat(e, t) : Qs({
        input: Yt(),
        fatal: Yt(),
        expected: ng()
      }).concat(e, t);
    }
  };
}, hg = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, vg = function(e) {
  return eg(e.cursor, e.buffer);
}, mg = function(e) {
  return e.cursor >= e.buffer.length;
}, yg = function(e) {
  return y(vg(e), pr(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, ee = globalThis && globalThis.__assign || function() {
  return ee = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t)
        Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, ee.apply(this, arguments);
}, Lr = function(e) {
  return function(t) {
    return tt(e, t, t);
  };
}, gg = function() {
  return function(e) {
    return rt(e);
  };
}, _g = function(e) {
  return function() {
    return rt(e);
  };
}, R = function(e) {
  return y($g(bg()), le(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? ge(r) : _g(n);
  }));
}, j = function(e, t) {
  return function(r) {
    return y(e(r), Wt(function(n) {
      return dg(n, [t]);
    }));
  };
}, bg = function() {
  return function(e) {
    return y(yg(e), Pr(function() {
      return rt(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return tt(r, n, e);
    }));
  };
}, Tg = function(e, t) {
  return function(r) {
    return y(e(r), Ln(function(n) {
      return y(t(n.value)(n.next), Ln(function(s) {
        return tt(s.value, s.next, r);
      }));
    }));
  };
}, Qt = function(e, t) {
  return function(r) {
    var n = e(r);
    return ug(n) || n.left.fatal ? n : y(t()(r), Wt(function(s) {
      return fg(n.left, s);
    }));
  };
}, $g = function(e) {
  return function(t) {
    return y(e(t), et(function(r) {
      return ee(ee({}, r), { value: [r.value, t] });
    }));
  };
}, ys = function(e) {
  return de(function() {
    return ge(e.empty);
  });
}, Dr = function() {
  return j(function(e) {
    return mg(e) ? tt(void 0, e, e) : rt(e);
  }, "end of file");
}, Ag = function(e) {
  return y(Ur(e), de(function() {
    return ge([]);
  }));
}, Ur = function(e) {
  return y(e, le(function(t) {
    return Hr(Yy(t), function(r) {
      return y(e, k(function(n) {
        return re(Xy(n)(r));
      }), de(function() {
        return ge(B(r));
      }));
    });
  }));
}, Sg = function(e, t) {
  return function(r) {
    return y(e, le(function() {
      return r;
    }), qr(function() {
      return t;
    }));
  };
}, gs = function(e) {
  return Sg(e, e);
}, Eg = function(e) {
  return Ag(R(te(e)));
}, Zr = function(e, t) {
  return y(t, k(function() {
    return Wy;
  }), de(function() {
    return wg(e, t);
  }));
}, wg = function(e, t) {
  return y(e, le(function(r) {
    return Hr(ai(r), function(n) {
      return y(t, k(function() {
        return B(n);
      }), de(function() {
        return y(e, k(function(s) {
          return re(Gy(s)(n));
        }));
      }));
    });
  }));
}, nt = function(e, t) {
  return function(r) {
    return y(e(r), et(function(n) {
      return ee(ee({}, n), { value: t(n.value) });
    }));
  };
}, _s = function(e, t) {
  return Jt(e, function(r) {
    return nt(t, r);
  });
}, Jt = function(e, t) {
  return Tg(e, t);
}, Hr = function(e, t) {
  var r = function(n) {
    return function(s) {
      return ye(s.value) ? re({ value: s.value.left, stream: s.next }) : B(tt(s.value.right, s.next, n));
    };
  };
  return function(n) {
    return og({ value: e, stream: n }, function(s) {
      var i = t(s.value)(s.stream);
      return ye(i) ? B(rt(s.stream, i.left.expected, i.left.fatal)) : r(n)(i.right);
    });
  };
}, xg = function(e, t) {
  return Qt(e, t);
}, k = function(e) {
  return function(t) {
    return nt(t, e);
  };
}, ge = Lr, le = function(e) {
  return function(t) {
    return Jt(t, e);
  };
}, qr = function(e) {
  return function(t) {
    return Jt(t, function(r) {
      return nt(e(r), function() {
        return r;
      });
    });
  };
}, de = function(e) {
  return function(t) {
    return xg(t, e);
  };
}, kg = "Parser", jg = function(e) {
  return {
    concat: function(t, r) {
      return _s(nt(t, function(n) {
        return function(s) {
          return e.concat(n, s);
        };
      }), r);
    }
  };
}, Rg = function(e) {
  return ee(ee({}, jg(e)), { empty: Lr(e.empty) });
}, Og = {
  URI: kg,
  map: nt,
  ap: _s,
  chain: Jt,
  chainRec: Hr
}, bs = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, Ts = function(e) {
  return function(t) {
    return y(t, k(function(r) {
      return bs({}, e, r);
    }));
  };
}, hr = function(e, t) {
  return function(r) {
    return y(r, le(function(n) {
      return y(t(n), k(function(s) {
        return bs(n, e, s);
      }));
    }));
  };
}, Cg = function(e) {
  return Ys(e)(e.empty);
};
Xs.concat;
var Ig = ys(yr), _e = function(e) {
  return j(R(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, Mg = function(e) {
  return j(R(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, Br = function(e) {
  return Ig(at(e));
}, at = function(e) {
  return y(Ur(e), k(function(t) {
    return t.join("");
  }));
}, Gr = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, vr = j(R(Gr), "a digit"), Ng = /^\s$/, $s = function(e) {
  return Ng.test(e);
}, As = j(R($s), "a whitespace"), Pg = function(e) {
  return e === "_";
}, st = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, Ss = function(e) {
  return st(e) || Gr(e) || Pg(e);
};
j(R(Ss), "a word character");
j(R(st), "a letter");
var Vg = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
j(R(Vg), "an unicode letter");
var Es = function(e) {
  return st(e) && e === e.toUpperCase();
};
j(R(Es), "an upper case letter");
var ws = function(e) {
  return st(e) && e === e.toLowerCase();
};
j(R(ws), "a lower case letter");
j(R(te(Gr)), "a non-digit");
var xs = j(R(te($s)), "a non-whitespace character");
j(R(te(Ss)), "a non-word character");
j(R(te(st)), "a non-letter character");
j(R(te(Es)), "anything but an upper case letter");
j(R(te(ws)), "anything but a lower case letter");
var ks = function(e) {
  return j(Og.chainRec(e, function(t) {
    return y(Ug(0, t), Pr(function() {
      return ge(B(e));
    }, function(r) {
      return y(_e(r), le(function() {
        return ge(re(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, mr = Cg(Rg(yr)), Ke = ys(yr), Lg = function(e) {
  return Ke(Dg(e));
}, Dg = function(e) {
  return y(Ur(e), k(function(t) {
    return t.join("");
  }));
}, Ug = function(e, t) {
  return e >= 0 && e < t.length ? Xe(t.charAt(e)) : Kt;
}, Zg = Br(As);
at(As);
Br(xs);
at(xs);
var Hg = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Kt : Xe(t);
};
j(y(mr([Ke(_e("-")), at(vr)]), k(function(e) {
  return +e;
})), "an integer");
j(y(mr([Ke(_e("-")), Br(vr), Ke(mr([_e("."), at(vr)]))]), le(function(e) {
  return y(Hg(e), Pr(function() {
    return gg();
  }, Lr));
})), "a float");
var qg = gs(_e('"'))(Lg(Qt(ks('\\"'), function() {
  return Mg('"');
})));
function js(e) {
  return function(t) {
    return t(hg(e.split("")));
  };
}
const Dn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), Bg = ["#", ":"], be = gs(Zg), zr = (e) => (t) => t.join(e), Fr = (e) => y(
  Eg((t) => e.includes(t)),
  k(zr(""))
), Gg = Q(
  Fr,
  qr(() => R(() => !0))
), Rs = y(
  qg,
  k((e) => JSON.parse(`"${e}"`))
), zg = y(
  be(Rs),
  de(
    () => y(
      Fr([":", `
`]),
      k(Fn)
    )
  )
), Fg = y(
  be(Rs),
  de(
    () => y(
      Fr([`
`]),
      k(Fn)
    )
  )
), Os = y(
  Ke(ks("#")),
  k(te(Kn))
), Cs = y(
  be(Os),
  Ts("commented"),
  hr("key", () => be(zg)),
  qr(() => _e(":")),
  hr("value", () => Fg)
), Kg = y(
  be(Os),
  Ts("commented"),
  hr("key", () => Qt(
    Gg([`
`]),
    () => y(
      Zr(R((e) => !0), Dr()),
      k(Q(
        hs,
        zr("")
      ))
    )
  )),
  k(Q(
    vs(({ key: e }) => !Kn(e))
  ))
), Wg = y(
  Zr(be(Cs), Dr())
), Qg = y(
  Zr(
    Qt(
      y(Cs, k(Xe)),
      () => y(
        Kg,
        k(Q(
          pr((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    Dr()
  ),
  k(Q(
    Ky(Q(
      vs(ag),
      pr((e) => e.value)
    ))
  ))
), Jg = (e) => {
  if (Bg.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, Un = (e) => Jg(e) ? JSON.stringify(e) : e, g_ = (e) => y(
  e,
  tg(
    Q(
      Dn("key", Un),
      Dn("value", Un),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  zr(`
`)
), Yg = (e) => y(
  Qg,
  js(e),
  Wt((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  et(
    ({ value: t }) => y(
      t,
      ps(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), __ = (e) => y(
  Wg,
  js(e),
  Wt((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  et(
    ({ value: t }) => y(
      t,
      ps(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), b_ = Q(
  Yg,
  et(hs),
  Vr(() => [])
), Xg = a.object({
  id: a.optional(a.string()),
  name: a.string(),
  variables: a.array(
    a.object({
      key: a.string(),
      value: a.string()
    })
  )
}), Zn = w({
  initial: !0,
  schema: Xg
}), Is = () => Math.random().toString(36).substring(2, 16), e_ = a.object({
  v: a.literal(1),
  id: a.string(),
  name: a.string(),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1).catch(!1)
      })
    ])
  )
}), t_ = w({
  initial: !1,
  schema: e_,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || Is(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), Ms = [
  // Common
  {
    key: "$guid",
    description: "A v4 style GUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : r === 14 ? t += "4" : r === 19 ? t += e.charAt(8 + Math.floor(Math.random() * 4)) : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$timestamp",
    description: "The current UNIX timestamp in seconds.",
    getValue: () => Math.floor(Date.now() / 1e3).toString()
  },
  {
    key: "$isoTimestamp",
    description: "The current ISO timestamp at zero UTC.",
    getValue: () => (/* @__PURE__ */ new Date()).toISOString()
  },
  {
    key: "$randomUUID",
    description: "A random 36-character UUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  // Text, numbers, and colors
  {
    key: "$randomAlphaNumeric",
    description: "A random alpha-numeric character.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      return e.charAt(Math.floor(Math.random() * e.length));
    }
  },
  {
    key: "$randomBoolean",
    description: "A random boolean value.",
    getValue: () => Math.random() < 0.5 ? "true" : "false"
  },
  {
    key: "$randomInt",
    description: "A random integer between 0 and 1000.",
    getValue: () => Math.floor(Math.random() * 1e3).toString()
  },
  {
    key: "$randomColor",
    description: "A random color.",
    getValue: () => {
      const e = ["red", "green", "blue", "yellow", "purple", "orange"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomHexColor",
    description: "A random hex value.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "#";
      for (let r = 0; r < 6; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomAbbreviation",
    description: "A random abbreviation.",
    getValue: () => {
      const e = [
        "SQL",
        "PCI",
        "JSON",
        "HTML",
        "CSS",
        "JS",
        "TS",
        "API"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Internet and IP addresses
  {
    key: "$randomIP",
    description: "A random IPv4 address.",
    getValue: () => Array.from(
      { length: 4 },
      () => Math.floor(Math.random() * 256)
    ).join(".")
  },
  {
    key: "$randomIPV6",
    description: "A random IPv6 address.",
    getValue: () => Array.from(
      { length: 8 },
      () => Math.floor(Math.random() * 65536).toString(16)
    ).join(":")
  },
  {
    key: "$randomMACAddress",
    description: "A random MAC address.",
    getValue: () => Array.from(
      { length: 6 },
      () => Math.floor(Math.random() * 256).toString(16)
    ).join(":")
  },
  {
    key: "$randomPassword",
    description: "A random 15-character alpha-numeric password.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let t = "";
      for (let r = 0; r < 15; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomLocale",
    description: "A random two-letter language code (ISO 639-1).",
    getValue: () => {
      const e = ["ny", "sr", "si"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomUserAgent",
    description: "A random user agent.",
    getValue: () => {
      const e = [
        "Mozilla/5.0 (Macintosh; U; Intel Mac OS X 10.9.8; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomProtocol",
    description: "A random internet protocol.",
    getValue: () => {
      const e = ["http", "https"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomSemver",
    description: "A random semantic version number.",
    getValue: () => Array.from(
      { length: 3 },
      () => Math.floor(Math.random() * 10)
    ).join(".")
  },
  // Names
  {
    key: "$randomFirstName",
    description: "A random first name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomLastName",
    description: "A random last name.",
    getValue: () => {
      const e = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomFullName",
    description: "A random first and last name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ], t = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return `${e[Math.floor(Math.random() * e.length)]} ${t[Math.floor(Math.random() * t.length)]}`;
    }
  },
  {
    key: "$randomNamePrefix",
    description: "A random name prefix.",
    getValue: () => {
      const e = ["Dr.", "Ms.", "Mr.", "Mrs.", "Miss", "Prof."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomNameSuffix",
    description: "A random name suffix.",
    getValue: () => {
      const e = ["I", "MD", "DDS", "PhD", "Esq.", "Jr."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Addresses
  {
    key: "$randomCity",
    description: "A random city name.",
    getValue: () => {
      const e = [
        "New York",
        "Los Angeles",
        "Chicago",
        "Houston",
        "Phoenix",
        "Philadelphia"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // profession
  {
    key: "$randomJobArea",
    description: "A random job area.",
    getValue: () => {
      const e = [
        "Mobility",
        "Intranet",
        "Configuration",
        "Development",
        "Design",
        "Testing"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobDescriptor",
    description: "A random job descriptor.",
    getValue: () => {
      const e = [
        "Forward",
        "Corporate",
        "Senior",
        "Junior",
        "Lead",
        "Principal"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobTitle",
    description: "A random job title.",
    getValue: () => {
      const e = [
        "International Creative Liaison",
        "Global Branding Officer",
        "Dynamic Data Specialist",
        "Internal Communications Consultant",
        "Productivity Analyst",
        "Regional Applications Developer"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobType",
    description: "A random job type.",
    getValue: () => {
      const e = ["Supervisor", "Manager", "Coordinator", "Director"];
      return e[Math.floor(Math.random() * e.length)];
    }
  }
  // TODO: Support various other predefined variables
], r_ = a.object({
  v: a.number()
}), T_ = Je({
  latestVersion: 1,
  versionMap: {
    0: Zn,
    1: t_
  },
  getVersion(e) {
    const t = r_.safeParse(e);
    return t.success ? t.data.v : Zn.schema.safeParse(e).success ? 0 : null;
  }
}), xt = /<<([^>]*)>>/g, kt = 10, Ns = "ENV_EXPAND_LOOP", Hn = 1;
function n_(e, t) {
  let r = e, n = 0;
  for (; r.match(xt) != null && n <= kt; )
    r = r.replace(xt, (s) => {
      const i = s.replace(/[<>]/g, ""), o = Ms.find(
        (l) => l.key === i
      );
      if (o)
        return o.getValue();
      const c = t.find((l) => l.key === i);
      return c && "value" in c ? c.value : s;
    }), n++;
  return n > kt ? re(Ns) : B(r);
}
const $_ = (e, t) => y(
  n_(e, t),
  Vr(() => e)
);
function a_(e, t, r = !1, n = !1) {
  if (!t || !e)
    return B(e);
  let s = e, i = 0, o = !1;
  for (; s.match(xt) != null && i <= kt && !o; )
    s = decodeURI(encodeURI(s)).replace(xt, (c, l) => {
      const u = Ms.find(
        (_) => _.key === l
      );
      if (u)
        return u.getValue();
      const h = t.find((_) => _ && _.key === l);
      return h && "value" in h ? h.secret && n ? (o = !0, `<<${l}>>`) : h.secret && r ? "*".repeat(
        h.value.length
      ) : h.value : "";
    }), i++;
  return i > kt ? re(Ns) : B(s);
}
const A_ = (e, t, r = !1, n = !1) => y(
  a_(e, t, r, n),
  Vr(() => e)
), s_ = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), S_ = (e) => {
  if (e.v && e.v === Hn)
    return e;
  const t = e.id || Is(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(s_);
  return {
    v: Hn,
    id: t,
    name: r,
    variables: n
  };
}, i_ = a.array(
  a.union([
    a.object({
      key: a.string(),
      value: a.string(),
      secret: a.literal(!1)
    }),
    a.object({
      key: a.string(),
      secret: a.literal(!0)
    }),
    a.object({
      key: a.string(),
      value: a.string()
    })
  ])
), qn = w({
  initial: !0,
  schema: i_
}), o_ = a.object({
  v: a.literal(1),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1)
      })
    ])
  )
}), c_ = w({
  initial: !1,
  schema: o_,
  up(e) {
    return {
      v: 1,
      variables: e.map((r) => "value" in r ? {
        key: r.key,
        value: r.value,
        secret: !1
      } : {
        key: r.key,
        secret: !0
      })
    };
  }
}), u_ = a.object({
  v: a.number()
}), E_ = Je({
  latestVersion: 1,
  versionMap: {
    0: qn,
    1: c_
  },
  getVersion(e) {
    const t = u_.safeParse(e);
    return t.success ? t.data.v : qn.schema.safeParse(e).success ? 0 : null;
  }
});
export {
  La as AuthCodeGrantTypeParams,
  sy as ClientCredentialsGrantTypeParams,
  Hy as CollectionSchemaVersion,
  T_ as Environment,
  Hn as EnvironmentSchemaVersion,
  kr as FormDataKeyValue,
  es as GQLHeader,
  as as GQL_REQ_SCHEMA_VERSION,
  E_ as GlobalEnvironment,
  Ms as HOPP_SUPPORTED_PREDEFINED_VARIABLES,
  y_ as HoppCollection,
  ns as HoppGQLAuth,
  Ft as HoppGQLAuthAPIKey,
  Ce as HoppGQLAuthBasic,
  Ie as HoppGQLAuthBearer,
  Me as HoppGQLAuthInherit,
  Oe as HoppGQLAuthNone,
  qa as HoppGQLAuthOAuth2,
  Nr as HoppGQLRequest,
  Gt as HoppRESTAuth,
  Pa as HoppRESTAuthAPIKey,
  Cr as HoppRESTAuthAWSSignature,
  ke as HoppRESTAuthBasic,
  je as HoppRESTAuthBearer,
  Re as HoppRESTAuthInherit,
  xe as HoppRESTAuthNone,
  oy as HoppRESTAuthOAuth2,
  Bt as HoppRESTHeaders,
  Or as HoppRESTParams,
  Rr as HoppRESTReqBody,
  l_ as HoppRESTReqBodyFormData,
  zt as HoppRESTRequest,
  dy as HoppRESTRequestResponse,
  za as HoppRESTRequestResponses,
  jr as HoppRESTRequestVariables,
  ly as HoppRESTResponseOriginalRequest,
  Zt as ImplicitOauthFlowParams,
  iy as PasswordGrantTypeParams,
  Fa as RESTReqSchemaVersion,
  d_ as ValidContentTypesList,
  My as getDefaultGQLRequest,
  yy as getDefaultRESTRequest,
  f_ as isEqualHoppRESTRequest,
  v_ as isHoppRESTRequest,
  hy as knownContentTypes,
  ds as makeCollection,
  m_ as makeGQLRequest,
  h_ as makeRESTRequest,
  $_ as parseBodyEnvVariables,
  n_ as parseBodyEnvVariablesE,
  b_ as parseRawKeyValueEntries,
  Yg as parseRawKeyValueEntriesE,
  A_ as parseTemplateString,
  a_ as parseTemplateStringE,
  g_ as rawKeyValueEntriesToString,
  p_ as safelyExtractRESTRequest,
  __ as strictParseRawKeyValueEntriesE,
  Ny as translateToGQLRequest,
  S_ as translateToNewEnvironment,
  s_ as translateToNewEnvironmentVariables,
  By as translateToNewGQLCollection,
  qy as translateToNewRESTCollection,
  gy as translateToNewRequest
};
